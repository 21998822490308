type Props = {
  onBack: () => void;
  heading: string;
};

const ComplexFormSectionHeading = ({ onBack, heading }: Props) => {
  return (
    <h5 class='d-block my-2 d-flex justify-content-between align-items-center my-3'>
      <span>{heading}</span>
      <button class='btn btn-secondary btn-sm' type='button' onClick={onBack}>
        &times;
      </button>
    </h5>
  );
};

export default ComplexFormSectionHeading;
