import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';

import ProceduresForm from '@/components/ProceduresForm';
import ProceduresTabs from '@/components/ProceduresTabs';
import { useProcedure } from '@/hooks';
import { updateProcedure } from '@/apis';
import { RequestStatus } from '@/enums';
import Loader from '@/components/Loader';
import { Procedure } from '@/types';
import { DEFAULT_ERROR } from '@/constants';

const ProceduresEdit = () => {
  const [{ matches }] = useRouter();
  const { entity } = useProcedure();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Procedure) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    updateProcedure(properties.id, properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  if (!entity) return <Loader />;

  return (
    <>
      <ProceduresTabs />
      <ProceduresForm
        onSubmit={onSubmit}
        entity={entity}
        requestStatus={requestStatus}
      />
    </>
  );
};

export default ProceduresEdit;
