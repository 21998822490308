import { route } from 'preact-router';

import EmptyList from '@/components/EmptyList';
import ChevronRight from '@/svgs/chevron-right.svg';
import { proceduresSignal } from '@/signals';
import { proceduresSorter } from '@/utils';
import Secondary from '@/components/Secondary';

const ProceduresIndex = () => {
  const activeProcedures = proceduresSignal.value.filter((c) => !c.deleted);

  return (
    <>
      <div class='mb-2'>
        <a
          class='btn btn-sm btn-primary'
          href={`/procedures/new?back=${encodeURIComponent('/procedures')}`}
        >
          Нова процедура
        </a>
      </div>

      {activeProcedures.length > 0 && (
        <>
          <h5 class='my-3'>Процедури:</h5>
          <ul class='list-group'>
            {[...activeProcedures].sort(proceduresSorter).map((p) => {
              return (
                <li
                  onClick={() =>
                    route(
                      `/procedures/${p.id}/edit?back=${encodeURIComponent(
                        '/procedures',
                      )}`,
                    )
                  }
                  key={p.id}
                  class='list-group-item p-2 d-flex justify-content-between align-items-center'
                >
                  <div class='text-truncate'>
                    {p.name} <Secondary>{`(${p.price2} грн.)`}</Secondary>
                  </div>
                  <ChevronRight />
                </li>
              );
            })}
          </ul>
        </>
      )}

      {activeProcedures.length === 0 && <EmptyList />}
    </>
  );
};

export default ProceduresIndex;
