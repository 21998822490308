import * as db from 'firebase/firestore';
import { b, docs, isInventoryValid, where } from './_common';
import { Product, Sale } from 'cosdb-types';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const createProduct = (props: Product) => {
  if (!isInventoryValid(props.inventory || {})) {
    return Promise.reject(new Error('INVENTORY_INVALID'));
  }

  return db.setDoc(db.doc(...b(), 'products', props.id), props).catch((e) => {
    logEvent(AnalyticsEvent.CreateProductError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  });
};

export const updateProduct = (id: string, props: Partial<Product>) => {
  if (!isInventoryValid(props.inventory || {})) {
    return Promise.reject(new Error('INVENTORY_INVALID'));
  }

  return db.updateDoc(db.doc(...b(), 'products', id), props).catch((e) => {
    logEvent(AnalyticsEvent.UpdateProductError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  });
};

export const getSalesByProductId = ({ productId }: { productId: string }) =>
  db
    .getDocs(db.query(db.collection(...b(), 'sales'), where({ productId })))
    .then(docs<Sale>)
    .catch((e) => {
      logEvent(AnalyticsEvent.GetSalesByProductIdError, {
        message: (e as { message: string }).message,
      });

      return Promise.reject(e);
    });
