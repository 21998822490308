import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';
import SalesForm from '@/components/SalesForm';
import SalesTabs from '@/components/SalesTabs';
import { RequestStatus } from '@/enums';
import { DEFAULT_ERROR } from '@/constants';
import { Sale } from 'cosdb-types';
import { createSaleAndUpdateProduct } from '@/apis/createSaleAndUpdateProduct';

const SalesNew = () => {
  const [{ matches }] = useRouter();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Sale) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    createSaleAndUpdateProduct({ sale: properties })
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch((err: Error) => {
        setRequestStatus(RequestStatus.DEFAULT);

        if (err.message === 'PRODUCT_NOT_FOUND') {
          alert(`Продукт ${properties.productId} не знайдено.`);
        } else {
          alert(DEFAULT_ERROR);
        }
      });
  };

  return (
    <>
      <SalesTabs />
      <SalesForm onSubmit={onSubmit} requestStatus={requestStatus} />
    </>
  );
};

export default SalesNew;
