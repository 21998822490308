import * as db from 'firebase/firestore';
import { b, docs, where } from './_common';
import { Sale } from 'cosdb-types';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const getSalesByClientId = ({ clientId }: { clientId: string }) => {
  return db
    .getDocs(db.query(db.collection(...b(), 'sales'), where({ clientId })))
    .then(docs<Sale>)
    .catch((e) => {
      logEvent(AnalyticsEvent.GetSalesByClientIdError, {
        message: (e as { message: string }).message,
      });

      return Promise.reject(e);
    });
};

export const getSalesByYearAndMonth = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  return db
    .getDocs(
      db.query(
        db.collection(...b(), 'sales'),
        where({ year }),
        where({ month }),
      ),
    )
    .then(docs<Sale>)
    .catch((e) => {
      logEvent(AnalyticsEvent.GetSalesByYearAndMonthError, {
        message: (e as { message: string }).message,
      });

      return Promise.reject(e);
    });
};

export const getSale = async (id: string) => {
  try {
    const d = await db.getDoc(db.doc(...b(), 'sales', id));
    if (d.exists()) return d.data() as Sale;
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.GetSaleError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
  return null;
};
