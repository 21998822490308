import { appointmentsSignal, clientsSignal, proceduresSignal } from '@/signals';
import {
  calculateTimespan,
  appointmentsSorter,
  getDate,
  getFullYear,
  getMonth,
  mergeRanges,
  isRangeInRanges,
} from '@/utils';

import '@/components/Timeslots/index.css';
import { Appointment, Day, Slot } from '@/types';
import { AppointmentStatus, Mode, PaymentStatus } from '@/enums';
import classNames from 'classnames';
import { HOUR, RESOLUTION, WORKING_HOURS } from '@/constants';
import { useState } from 'preact/hooks';
import { workDaysSignal } from '@/signals/workdays';
import { companySignal } from '@/signals/company';

type Props = {
  activeDay: number | null;
  month: number;
  year: number;
  onAppointmentClick: (a: Appointment) => void;
  onSlotClick: (slot: Slot) => void;
  filteredAppointments: string[];
  mode: Mode;
};

const hourToString = (hour: number, minutes: number) => {
  const start = `${hour < 10 ? `0${hour}` : hour.toString()}:${
    minutes === 0 ? '00' : minutes
  }`;

  return start;
};

const Timeslots = ({
  activeDay,
  month,
  year,
  onAppointmentClick,
  onSlotClick,
  filteredAppointments = [],
  mode,
}: Props) => {
  const [breakPickerActive, setBreakPickerActive] = useState<number | false>(
    false,
  );
  const [timepickerActive, setTimepickerActive] = useState<number | false>(
    false,
  );

  if (!activeDay) return null;

  const dayPredicate = (a: Appointment) =>
    a.month === month &&
    a.year === year &&
    a.day === activeDay &&
    !filteredAppointments.includes(a.id);

  const breaks = workDaysSignal.value[activeDay as Day] as Slot[];

  const onSaveBreak = (e: Event) => {
    if (!breakPickerActive) return;

    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const minutes = parseInt(formData.get('minutes')!.toString()) || 0;

    onSlotClick([breakPickerActive, breakPickerActive + minutes]);
    setBreakPickerActive(false);
  };

  const filteredByDate = appointmentsSignal.value.filter(dayPredicate);

  const getDuration = (a: Appointment) => {
    const procedures = proceduresSignal.value.filter((p) =>
      a.procedureId.includes(p.id),
    );

    return a.duration || procedures[0].duration;
  };

  const takenSlots = mergeRanges([
    ...filteredByDate.map(
      (i) => [i.slot[0], i.slot[0] + getDuration(i)] as Slot,
    ),
    ...breaks,
  ]).filter((i) => i !== undefined);

  const workingHours = [
    companySignal.value?.settings?.startTime === 480 ? 8 : undefined,
    ...WORKING_HOURS,
  ].filter((i) => i !== undefined) as number[];

  return (
    <div class='Grid'>
      {breakPickerActive !== false && (
        <>
          <div
            onClick={() => setBreakPickerActive(false)}
            style={{
              zIndex: 998,
              position: 'fixed',
              inset: 0,
              backgroundColor: 'rgba(255, 255, 255, .5)',
            }}
          />
          <ul
            class='dropdown-menu show'
            data-popper-placement='top-start'
            style={{
              zIndex: 999,
              position: 'fixed',
              left: '.5rem',
              right: '.5rem',
              bottom: '2.5rem',
            }}
          >
            <li>
              <div class='dropdown-item'>
                <form onSubmit={onSaveBreak}>
                  <div class='input-group'>
                    <input
                      name='minutes'
                      type='text'
                      class='form-control form-control-sm'
                      placeholder='Кількість хвилин'
                    />
                    <input
                      type='submit'
                      class='btn btn-outline-primary btn-sm'
                      value='Зберегти'
                    />
                  </div>
                </form>
              </div>
            </li>
          </ul>
        </>
      )}
      {timepickerActive !== false && (
        <>
          <div
            onClick={() => setTimepickerActive(false)}
            style={{
              zIndex: 998,
              position: 'fixed',
              inset: 0,
              backgroundColor: 'rgba(255, 255, 255, .5)',
            }}
          />
          <ul
            class='dropdown-menu show'
            data-popper-placement='top-start'
            style={{
              zIndex: 999,
              position: 'fixed',
              left: '.5rem',
              right: '.5rem',
              bottom: '2.5rem',
            }}
          >
            {[0, 15, 30, 45].map((i, index) => {
              return (
                <>
                  {index !== 0 && (
                    <li>
                      <hr class='dropdown-divider' />
                    </li>
                  )}
                  <li>
                    <a
                      class={classNames('dropdown-item text-center', {
                        disabled: isRangeInRanges(takenSlots, [
                          timepickerActive * 60 + i,
                          timepickerActive * 60 + i + RESOLUTION,
                        ]),
                      })}
                      onClick={() => {
                        if (mode === Mode.SET_BREAK) {
                          setTimepickerActive(false);
                          setBreakPickerActive(timepickerActive * 60 + i);
                        } else {
                          onSlotClick([
                            timepickerActive * 60 + i,
                            timepickerActive * 60 + i + RESOLUTION,
                          ]);
                          setTimepickerActive(false);
                        }
                      }}
                    >
                      {hourToString(timepickerActive, i)}
                    </a>
                  </li>
                </>
              );
            })}
          </ul>
        </>
      )}

      {workingHours.map((hour) => {
        return (
          <button
            key={hour}
            class='Grid__slot'
            onClick={() => setTimepickerActive(hour)}
            type='button'
          >
            {`${hour < 10 ? `0${hour}` : hour.toString()}`}
          </button>
        );
      })}

      {breaks.map((range) => {
        return (
          <div
            class='bg-opacity-25 bg-warning d-flex justify-content-center align-items-center Grid__break'
            style={{
              top: range[0] - workingHours[0] * HOUR + 1,
              height: range[1] - range[0] - 2,
            }}
          >
            {(mode === Mode.SET_BREAK && (
              <button
                class='btn btn-sm btn-outline-danger'
                onClick={() => onSlotClick(range)}
              >
                Видалити
              </button>
            )) ||
              `Перерва: ${calculateTimespan(range, range[1] - range[0])}`}
          </div>
        );
      })}

      {filteredByDate.sort(appointmentsSorter).map((a, index) => {
        const client = clientsSignal.value.find((c) => c.id === a.clientId);
        const procedures = proceduresSignal.value.filter((p) =>
          a.procedureId.includes(p.id),
        );
        const names = procedures.map((p) => p.name).join(', ');
        const duration = getDuration(a);

        if (!procedures.length) return null;

        const d = getDate();
        const m = getMonth();
        const y = getFullYear();

        const nonProcessed =
          new Date(y, m, d).getTime() -
            new Date(a.year, a.month, a.day).getTime() >=
            0 &&
          a.paymentStatus === PaymentStatus.NOT_PAID &&
          a.status === AppointmentStatus.PENDING;

        return (
          <div
            data-locator={`appointment-${index}`}
            class={classNames('Grid__appointment', {
              'Grid__appointment--warning': nonProcessed,
            })}
            style={{
              top: a.slot[0] - workingHours[0] * HOUR + 1,
              height: duration - 2,
            }}
            key={a.id}
            onClick={() => onAppointmentClick(a)}
          >
            <div class='d-flex justify-content-between'>
              <div style={{ whiteSpace: 'nowrap' }} data-locator='timespan'>
                {calculateTimespan(a.slot, duration)}
              </div>
              <div class='text-truncate'>
                <b>{`${client?.firstName || 'UNKNOWN_CLIENT_FIRST_NAME'} ${
                  client?.lastName || 'UNKNOWN_CLIENT_LAST_NAME'
                }`}</b>
              </div>
            </div>
            <div class='d-flex justify-content-between'>
              <div class='text-truncate'>{names}</div>
            </div>
            <div class='text-truncate'>
              <i>{a.notes}</i>
            </div>
            {a.status === AppointmentStatus.CANCELLED && (
              <span
                class='badge text-bg-danger'
                style={{ position: 'absolute', right: 5, bottom: 5 }}
              >
                Відмінено
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Timeslots;
