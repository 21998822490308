import { Slot } from '@/types';

export enum InputComplexType {
  CLIENT = 'CLIENT',
  DATETIME = 'DATETIME',
}

const LABELS = {
  [InputComplexType.CLIENT]: 'Клієнт',
  [InputComplexType.DATETIME]: 'День та час',
};

const NAMES = {
  [InputComplexType.CLIENT]: 'clientId',
  [InputComplexType.DATETIME]: 'datetimeId',
};

type CommonProps = {
  displayValue?: string;
  onClick?: () => void;
};

type ClientProps = CommonProps & {
  type: InputComplexType.CLIENT;
  value: string;
};

type DateTimeProps = CommonProps & {
  type: InputComplexType.DATETIME;
  value: {
    month: number | null;
    year: number | null;
    day: number | null;
    slot: Slot | null;
  };
};

type Props = DateTimeProps | ClientProps;

const InputComplex = ({ type, onClick, displayValue, value }: Props) => {
  return (
    <div class='mb-2' style={{ flex: 1 }}>
      <label for={NAMES[type]} class='form-label'>
        {LABELS[type]}
      </label>

      <input
        readonly
        id={NAMES[type]}
        type='text'
        class='form-control text-truncate'
        value={displayValue || undefined}
        onClick={onClick}
      />

      {type === InputComplexType.CLIENT && (
        <input type='hidden' name={NAMES[type]} value={value} />
      )}

      {type === InputComplexType.DATETIME && (
        <>
          <input type='hidden' name='month' value={value.month || ''} />
          <input type='hidden' name='year' value={value.year || ''} />
          <input type='hidden' name='day' value={value.day || ''} />
          <input
            type='hidden'
            name='slot'
            value={JSON.stringify(value.slot || [])}
          />
        </>
      )}
    </div>
  );
};

export default InputComplex;
