import { route, useRouter } from 'preact-router';

import ArrowLeft from '@/svgs/arrow-left.svg';
import Trash from '@/svgs/trash.svg';
import { DEFAULT_ERROR } from '@/constants';
import { Sale } from 'cosdb-types';
import { deleteSaleAndUpdateProduct } from '@/apis/deleteSaleAndUpdateProduct';

type Props = {
  entity?: Sale;
};

const SalesTabs = ({ entity }: Props) => {
  const [{ matches }] = useRouter();

  const onDelete = () => {
    if (!entity) return;

    const p = confirm('Чи справді ви хочете видалити продаж?');

    if (!p) return;

    deleteSaleAndUpdateProduct({ sale: entity })
      .then(() => route(matches?.back || '/'))
      .catch(() => alert(DEFAULT_ERROR));
  };

  return (
    <ul class='nav nav-tabs tabs mb-2'>
      <li class='nav-item'>
        <a href={matches?.back || '/'} class='nav-link text-primary'>
          <ArrowLeft />
        </a>
      </li>
      {!entity && (
        <li class='nav-item'>
          <button type='button' class='nav-link active'>
            Новий продаж
          </button>
        </li>
      )}
      {entity && (
        <>
          <li class='nav-item'>
            <span class='nav-link active'>Інфо</span>
          </li>
          <li class='nav-item ms-auto'>
            <button
              data-locator='delete-sale'
              type='button'
              aria-label='Видалити'
              onClick={onDelete}
              class='nav-link text-danger'
            >
              <Trash />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default SalesTabs;
