import { Appointment } from '@/types';
import * as db from 'firebase/firestore';
import { b, docs, where } from './_common';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const getAppointmentsByClientId = async ({
  clientId,
}: {
  clientId: string;
}) => {
  try {
    const el = await db.getDocs(
      db.query(db.collection(...b(), 'appointments'), where({ clientId })),
    );

    return docs<Appointment>(el);
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.GetAppointmentsByClientIdError, {
      message: (e as { message: string }).message,
    });

    return [];
  }
};

export const getAppointmentsByYearAndMonth = async ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  try {
    const el = await db.getDocs(
      db.query(
        db.collection(...b(), 'appointments'),
        where({ year }),
        where({ month }),
      ),
    );

    return docs<Appointment>(el);
  } catch (e) {
    logEvent(AnalyticsEvent.GetAppointmentsByYearAndMonthError, {
      message: (e as { message: string }).message,
    });

    return [];
  }
};

export const getAppointment = async (id: string) => {
  try {
    const d = await db.getDoc(db.doc(...b(), 'appointments', id));

    if (d.exists()) return d.data() as Appointment;

    return null;
  } catch (e) {
    logEvent(AnalyticsEvent.GetAppointmentError, {
      message: (e as { message: string }).message,
    });

    return null;
  }
};

export const createAppointment = (props: Appointment) =>
  db.setDoc(db.doc(...b(), 'appointments', props.id), props).catch((e) => {
    logEvent(AnalyticsEvent.CreateAppointmentError, { message: e.message });

    throw e;
  });

export const updateAppointment = (id: string, props: Partial<Appointment>) =>
  db.updateDoc(db.doc(...b(), 'appointments', id), props).catch((e) => {
    logEvent(AnalyticsEvent.UpdateAppointmentError, { message: e.message });

    throw e;
  });

export const deleteAppointment = (id: string) =>
  db.deleteDoc(db.doc(...b(), 'appointments', id)).catch((e) => {
    logEvent(AnalyticsEvent.DeleteAppointmentError, { message: e.message });

    throw e;
  });
