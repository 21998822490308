import { useRouter } from 'preact-router';
import { productsSignal } from '@/signals';

export const useProduct = () => {
  const [{ matches }] = useRouter<{ productId: string; entityId: string }>();
  const entity =
    productsSignal.value.find(
      (p) => p.id === matches?.productId || p.id === matches?.entityId,
    ) || null;

  return { entity };
};
