import { useState } from 'preact/hooks';
import { useRouter } from 'preact-router';
import { computed } from '@preact/signals';

import {
  productsSignal,
  brandsSignal,
  buildNewProcurement,
  expensesSignal,
} from '@/signals';
import ButtonSubmit from '@/components/ButtonSubmit';
import { productsSorter } from '@/utils';
import { WorkDays } from '@/types';
import { RequestStatus } from '@/enums';
import SimplifiedDatepicker from './SimplifiedDatepicker';
import { Procurement, ProcurementType, Product } from 'cosdb-types';
import { NumberInput } from './NumberInput';

type Props = {
  onSubmit: (n: Procurement, o: Procurement | undefined) => void;
  entity?: Procurement;
  requestStatus: RequestStatus;
};

const ProcurementsForm = ({ onSubmit, entity, requestStatus }: Props) => {
  const [{ matches, url }] =
    useRouter<Record<keyof Procurement | 'back' | 'entityType', string>>();

  const getProduct = (id?: string) =>
    productsSignal.value.find((_p) => _p.id === id);

  const model = entity || buildNewProcurement({}, matches);

  const defaultState = {
    _brand: getProduct(model.entityId)?.brandId || null,
    ...model,
    days: {} as WorkDays,
    step: 0,
  };

  const [state, _setState] = useState(defaultState);

  type StateType = typeof defaultState;

  const setState = (args: Partial<StateType>) =>
    _setState((s) => ({ ...s, ...args }));

  const everythingIsInPlace = state.entityId && state.day;

  const byBrandSignal = (brand: string) =>
    computed(() => productsSignal.value.filter((_p) => _p.brandId === brand));

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const amount = parseFloat(entries.amount as string);
    if (amount < 1) {
      return alert(
        'Закупівлю не створено: кількість не може бути менше одиниці.',
      );
    }

    const processed = {
      entityId: entries.entityId.toString(),
      id: entries.id.toString(),
      notes: entries.notes.toString(),
      price: parseFloat((entries.price as string).replace(',', '.')),
      year: +entries.year,
      month: +entries.month - 1,
      day: +entries.day,
      amount: parseFloat(entries.amount as string),
      type: (entries.type?.toString() as ProcurementType) || state.type,
    };

    onSubmit(processed, entity);
  };

  const productsAvailable = (_p: Product) =>
    Object.values(_p.inventory).length > 0;

  return (
    <>
      {/* Top section */}
      {state.step === 0 && (
        <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
          <div className='mb-2'>
            <label class='form-label' htmlFor='entityType'>
              Категорія
            </label>
            <select
              name='entityType'
              id='entityType'
              className='form-select'
              onChange={(e: Event) => {
                const type = (e.target as HTMLInputElement)
                  .value as ProcurementType;

                setState({ type });
              }}
            >
              {[
                {
                  type: '',
                  label: 'Обрати категорію',
                },
                ...(matches.entityType === 'products' || !matches.entityType
                  ? [
                      {
                        type: ProcurementType.PRODUCT_FOR_SALE,
                        label: 'Продукт на продаж',
                      },
                      {
                        type: ProcurementType.PRODUCT_FOR_WORK,
                        label: 'Продукт для роботи',
                      },
                    ]
                  : []),
                ...(matches.entityType === 'expenses' || !matches.entityType
                  ? [
                      {
                        type: ProcurementType.EXPENSE,
                        label: 'Інші витрати',
                      },
                    ]
                  : []),
              ].map(({ type, label }) => (
                <option
                  value={type}
                  selected={(type as ProcurementType) === state.type}
                >
                  {label}
                </option>
              ))}
            </select>
          </div>

          {state.type === ProcurementType.EXPENSE && (
            <div className='mb-2'>
              <label class='form-label' htmlFor='entityId'>
                Група витрат
              </label>
              <select
                name='entityId'
                id='entityId'
                className='form-select'
                onChange={(e: Event) => {
                  setState({
                    entityId: (e.target as HTMLInputElement)
                      .value as ProcurementType,
                  });
                }}
              >
                {expensesSignal.value.map((expense) => (
                  <option
                    value={expense.id}
                    selected={expense.id === state.entityId}
                  >
                    {expense.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {(state.type === ProcurementType.PRODUCT_FOR_SALE ||
            state.type === ProcurementType.PRODUCT_FOR_WORK) && (
            <>
              <div className='mb-2'>
                <label class='form-label' htmlFor='_brand'>
                  Бренд
                </label>
                <select
                  name='_brand'
                  id='_brand'
                  className='form-select'
                  onChange={(e: Event) => {
                    setState({
                      _brand: (e.target as HTMLInputElement).value,
                      entityId: '',
                    });
                  }}
                >
                  <option value='' selected={!state._brand}>
                    Обрати бренд
                  </option>
                  {brandsSignal.value.map((brand) => (
                    <option
                      value={brand.id}
                      selected={brand.id === state._brand}
                    >
                      {brand.value}
                    </option>
                  ))}
                </select>
              </div>

              <div className='mb-2'>
                <label class='form-label' htmlFor='entityId'>
                  Продукт
                </label>
                <select
                  name='entityId'
                  id='entityId'
                  className='form-select'
                  disabled={!state._brand}
                  onChange={(e: Event) => {
                    setState({
                      entityId: (e.target as HTMLInputElement).value,
                    });
                  }}
                >
                  <option value='' selected={!state.entityId}>
                    Обрати продукт
                  </option>
                  {(state._brand ? [...byBrandSignal(state._brand).value] : [])
                    .sort(productsSorter)
                    .map((product) => (
                      <option
                        value={product.id}
                        selected={product.id === state.entityId}
                      >
                        {!productsAvailable(product)
                          ? `(0) ${product.name}`
                          : product.name}
                      </option>
                    ))}
                </select>
              </div>
            </>
          )}

          <div className='mb-2'>
            <SimplifiedDatepicker
              required
              day={state.day}
              month={state.month + 1}
              year={state.year}
            />
          </div>

          <div className='mb-2'>
            <NumberInput
              required
              label='Кількість'
              inputMode='numeric'
              _key='amount'
              defaultValue={state.amount}
            />
          </div>

          <div className='mb-2'>
            <NumberInput
              required
              label='Собівартість'
              inputMode='decimal'
              _key='price'
              defaultValue={state.price}
            />
          </div>

          <div class='mb-2'>
            <label for='notes' class='form-label'>
              Нотатки
            </label>
            <textarea
              class='form-control'
              id='notes'
              name='notes'
              value={state.notes}
              rows={4}
              onChange={(e) =>
                setState({ notes: (e.target as HTMLInputElement).value })
              }
            />
          </div>

          <input type='hidden' name='id' value={state.id} />

          <ButtonSubmit
            requestStatus={requestStatus}
            disabled={!everythingIsInPlace}
            text={
              url.match('/edit')
                ? 'Зберегти зміни'
                : (state.type === ProcurementType.EXPENSE &&
                    'Створити витрату') ||
                  'Створити закупівлю'
            }
          />
        </form>
      )}
    </>
  );
};

export default ProcurementsForm;
