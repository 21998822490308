import { useEffect, useState } from 'preact/hooks';
import { useRouter } from 'preact-router';
import { getSale } from '@/apis';
import { Sale } from 'cosdb-types';

export const useSale = () => {
  const [entity, setEntity] = useState<Sale | null>(null);
  const [{ matches }] = useRouter<{ saleId: string }>();

  if (!matches?.saleId) return { entity: null };

  useEffect(() => {
    getSale(matches?.saleId)
      .then((d) => setEntity(d))
      .catch(() =>
        alert('Продаж не знайдено в списку продажів. Спробуйте пізніше.'),
      );
  }, []);

  return { entity };
};
