import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';
import ProcurementsForm from '@/components/ProcurementsForm';
import ProcurementsTabs from '@/components/ProcurementsTabs';
import { RequestStatus } from '@/enums';
import { DEFAULT_ERROR } from '@/constants';
import { Procurement } from 'cosdb-types';
import { createProcurementAndUpdateProduct } from '@/apis/createProcurementAndUpdateProduct';

const ProcurementsNew = () => {
  const [{ matches }] = useRouter();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Procurement) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);
    createProcurementAndUpdateProduct({ procurement: properties })
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch((err: Error) => {
        setRequestStatus(RequestStatus.DEFAULT);

        if (err.message === 'PRODUCT_NOT_FOUND') {
          alert(
            `Продукт ${properties.entityId} не знайдено, зверніться до адміністратора.`,
          );
        } else {
          alert(DEFAULT_ERROR);
        }
      });
  };

  return (
    <>
      <ProcurementsTabs />
      <ProcurementsForm onSubmit={onSubmit} requestStatus={requestStatus} />
    </>
  );
};

export default ProcurementsNew;
