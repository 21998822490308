import { route } from 'preact-router';

import EmptyList from '@/components/EmptyList';
import ChevronRight from '@/svgs/chevron-right.svg';
import { expensesSignal } from '@/signals';
import { expensesSorter } from '@/utils';

const ExpensesIndex = () => {
  return (
    <>
      <div class='mb-2'>
        <a
          class='btn btn-sm btn-primary'
          href={`/expenses/new?back=${encodeURIComponent('/expenses')}`}
        >
          Нова група
        </a>
      </div>

      {expensesSignal.value.length > 0 && (
        <>
          <h5 class='my-3'>Групи витрат:</h5>
          <ul class='list-group'>
            {[...expensesSignal.value].sort(expensesSorter).map((p) => {
              return (
                <li
                  onClick={() =>
                    route(
                      `/expenses/${p.id}/edit?back=${encodeURIComponent(
                        '/expenses',
                      )}`,
                    )
                  }
                  key={p.id}
                  class='list-group-item p-2 d-flex justify-content-between align-items-center'
                >
                  <div>{p.name}</div>
                  <ChevronRight />
                </li>
              );
            })}
          </ul>
        </>
      )}

      {expensesSignal.value.length === 0 && <EmptyList />}
    </>
  );
};

export default ExpensesIndex;
