import { useState } from 'preact/hooks';
import { route } from 'preact-router';

import ChevronRight from '@/svgs/chevron-right.svg';
import { productsSignal, brandsSignal, brandNameById } from '@/signals';
import { brandsSorter } from '@/utils';
import EmptyList from '@/components/EmptyList';

const ProductsBrands = () => {
  const [tab, setTab] = useState('brands');
  const sumCost = productsSignal.value.reduce((acc, curr) => {
    const prices =
      Object.entries(curr.inventory).reduce(
        (_acc: number, [, { amount, cost }]) => _acc + +cost * amount,
        0,
      ) || 0;

    return acc + prices;
  }, 0);

  const sumPrice = productsSignal.value.reduce((acc, curr) => {
    const prices =
      Object.entries(curr.inventory).reduce(
        (_acc: number, [, { amount }]) => _acc + +curr.price * amount,
        0,
      ) || 0;

    return acc + prices;
  }, 0);

  return (
    <>
      <div class='mb-2'>
        <a
          class='btn btn-sm btn-primary'
          href={`/products/new?back=${encodeURIComponent(`/products`)}`}
        >
          Новий продукт
        </a>
      </div>

      {brandsSignal.value.length > 0 && (
        <>
          <ul class='nav nav-tabs mb-2'>
            <li class='nav-item'>
              <a
                onClick={() => setTab('brands')}
                class={`nav-link ${tab === 'brands' ? 'active' : ''}`}
              >
                Бренди
              </a>
            </li>
            <li class='nav-item'>
              <a
                onClick={() => setTab('out-of-stock')}
                class={`nav-link ${tab === 'out-of-stock' ? 'active' : ''}`}
              >
                Продукти, що закінчилися
              </a>
            </li>
          </ul>

          {tab === 'out-of-stock' && (
            <ul class='list-group'>
              {[...productsSignal.value]
                .filter(
                  (product) => Object.keys(product.inventory).length === 0,
                )
                .sort((a, b) => {
                  if (
                    brandNameById(a.brandId) + a.name >
                    brandNameById(b.brandId) + b.name
                  )
                    return 1;
                  if (
                    brandNameById(a.brandId) + a.name <
                    brandNameById(b.brandId) + b.name
                  )
                    return -1;
                  return 0;
                })
                .map((product) => (
                  <li
                    onClick={() => {
                      route(
                        `/products/${product.id}/edit?back=${encodeURIComponent(
                          '/products',
                        )}`,
                      );
                    }}
                    key={product.id}
                    class='list-group-item p-2 d-flex justify-content-between align-items-center'
                  >
                    <div className='text-truncate'>{`${brandNameById(
                      product.brandId,
                    )} / ${product.name}`}</div>
                    <ChevronRight style={{ flexShrink: 0 }} />
                  </li>
                ))}
            </ul>
          )}

          {tab === 'brands' && (
            <>
              <ul class='list-group'>
                {[...brandsSignal.value].sort(brandsSorter).map((p) => {
                  return (
                    <li
                      onClick={() => route(`/products?brand=${p.id}`)}
                      key={p.id}
                      class='list-group-item p-2 d-flex justify-content-between align-items-center'
                    >
                      <div class='text-truncate'>{p.value}</div>
                      <ChevronRight />
                    </li>
                  );
                })}
              </ul>

              <div
                class='mt-3'
                style={{ fontSize: 14, color: '#777777', textAlign: 'right' }}
              >
                <i data-locator='products-cost'>{`Собівартість на продаж: ${Intl.NumberFormat(
                  'uk-UA',
                  {
                    style: 'currency',
                    currency: 'UAH',
                  },
                ).format(sumCost)}`}</i>
                <br />
                <i data-locator='products-price'>{`Вартість на продаж: ${Intl.NumberFormat(
                  'uk-UA',
                  {
                    style: 'currency',
                    currency: 'UAH',
                  },
                ).format(sumPrice)}`}</i>
              </div>
            </>
          )}
        </>
      )}

      {brandsSignal.value.length === 0 && <EmptyList />}
    </>
  );
};

export default ProductsBrands;
