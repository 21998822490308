import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';
import { useProcurement } from '@/hooks';
import Loader from '@/components/Loader';
import ProcurementsForm from '@/components/ProcurementsForm';
import ProcurementsTabs from '@/components/ProcurementsTabs';
import { RequestStatus } from '@/enums';
import { Procurement } from 'cosdb-types';
import { updateProcurementAndUpdateProducts } from '@/apis/updateProcurementAndUpdateProducts';

const ProcurementsEdit = () => {
  const [{ matches }] = useRouter();
  const { entity } = useProcurement();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Procurement, old: Procurement | undefined) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);
    updateProcurementAndUpdateProducts({
      newProcurement: properties,
      oldProcurement: old!,
    })
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch((err: Error) => {
        setRequestStatus(RequestStatus.DEFAULT);
        if (err.message === 'PROCUREMENT_DATA_DIRTY') {
          alert(
            'Не можна поміняти цю закупілю, схоже вже були продажі банок з неї.',
          );
        } else {
          alert('Сталася помилка, зміни не збережені.');
        }
      });
  };

  if (!entity) return <Loader />;

  return (
    <div style={{ display: 'contents' }} data-id={entity.id}>
      <ProcurementsTabs entity={entity} />
      <ProcurementsForm
        onSubmit={onSubmit}
        entity={entity}
        requestStatus={requestStatus}
      />
    </div>
  );
};

export default ProcurementsEdit;
