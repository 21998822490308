import { OrderStatus, Product } from 'cosdb-types';
import EmptyList from './EmptyList';
import { getOrdersByProductId } from '@/signals/orders';
import { timestampToDate } from '@/utils';

export const Inventory = ({ product }: { product: Product }) => {
  const inStock = Object.entries(product.inventory).length > 0;
  const activeOrders = getOrdersByProductId(product.id, OrderStatus.OPEN);

  return (
    <>
      <label for='price1' class='form-label'>
        {`В наявності (${Object.values(product.inventory).reduce(
          (acc, { amount }) => acc + amount,
          0,
        )}):`}
      </label>
      {(inStock && (
        <table class='table table-bordered table-sm'>
          <thead>
            <tr>
              <th>Кількість</th>
              <th>Собівартість</th>
              <th>Дата</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(product.inventory).map(([, v], index) => (
              <tr data-locator={`prices-row-${index}`}>
                <td>{v.amount} од.</td>
                <td>{v.cost} грн.</td>
                <td>{timestampToDate(v.timestamp)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )) || <EmptyList />}
      {activeOrders.length > 0 && (
        <div class='alert alert-info px-3 py-2' role='alert'>
          {`Одиниць продукту в активних замовленнях: ${activeOrders.reduce(
            (acc, order) =>
              acc +
              (order.products.find((p) => p.id === product.id)?.amount || 0),
            0,
          )}`}
        </div>
      )}
    </>
  );
};
