import { Inventory, Sale } from 'cosdb-types';
import * as db from 'firebase/firestore';
import { b, isInventoryValid } from './_common';
import { getProductById } from '@/signals';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';

export const updateSaleAndUpdateProducts = ({
  newSale,
  oldSale,
}: {
  newSale: Sale;
  oldSale: Sale;
}) => {
  try {
    const sameProduct = newSale.productId === oldSale.productId;

    const batch = db
      .writeBatch(firestore)
      .update(db.doc(...b(), 'sales', newSale.id), newSale);

    if (!sameProduct) {
      const [newProcurementId] = Object.entries(newSale.inventory)[0];
      const [
        oldProcurementId,
        { cost: oldProcurementCost, timestamp: oldProcurementTimestamp },
      ] = Object.entries(oldSale.inventory)[0];

      const newProduct = getProductById(newSale.productId)!;
      const oldProduct = getProductById(oldSale.productId)!;

      if (!newProduct || !oldProduct)
        return Promise.reject('PRODUCT_NOT_FOUND');

      const { inventory: newProductInventory } = newProduct;
      const { inventory: oldProductInventory } = oldProduct;

      const newProductNewInventory: Inventory = Object.fromEntries(
        Object.entries(newProductInventory).map(
          ([k, { amount, cost, timestamp }]) => {
            if (k !== newProcurementId) return [k, { amount, cost, timestamp }];
            return [k, { amount: amount - 1, cost, timestamp }];
          },
        ),
      );

      if (!isInventoryValid(newProductNewInventory)) {
        return Promise.reject(new Error('INVENTORY_INVALID'));
      }

      const oldProductNewInventory: Inventory = {
        ...oldProductInventory,
        [oldProcurementId]: oldProductInventory[oldProcurementId]
          ? {
              ...oldProductInventory[oldProcurementId],
              amount: oldProductInventory[oldProcurementId].amount + 1,
            }
          : {
              amount: 1,
              cost: oldProcurementCost,
              timestamp: oldProcurementTimestamp,
            },
      };

      if (!isInventoryValid(oldProductNewInventory)) {
        return Promise.reject(new Error('INVENTORY_INVALID'));
      }

      batch
        .update(db.doc(...b(), 'products', oldProduct.id), {
          inventory: oldProductNewInventory,
        })
        .update(db.doc(...b(), 'products', newProduct.id), {
          inventory: newProductNewInventory,
        });
    }

    return batch.commit();
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.UpdateSaleAndUpdateProductsError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
};
