import { useState } from 'preact/hooks';
import { getMonth, getFullYear } from '@/utils';
import { userSignal } from '@/signals';
import Loader from '@/components/Loader';
import CalendarHeader from '@/components/Calendar/Header';
import { AnalyticsSales } from './Sales';
import AnalyticsAppointments from './Appointments';
import AnalyticsExpenses from './Expenses';
import { companySignal } from '@/signals/company';

const Analytics = () => {
  const [tab, setTab] = useState<'procedures' | 'sales' | 'expenses'>('sales');
  const [month, setMonth] = useState(getMonth());
  const [year, setYear] = useState(getFullYear());

  if (!userSignal.value) return <Loader />;

  return (
    <>
      <CalendarHeader
        month={month}
        year={year}
        onChange={(m, y) => {
          setMonth(m);
          setYear(y);
        }}
      />

      <ul class='nav nav-tabs'>
        <li class='nav-item'>
          <a
            onClick={() => setTab('sales')}
            class={`nav-link ${tab === 'sales' ? 'active' : ''}`}
          >
            Продажі
          </a>
        </li>
        <li class='nav-item'>
          <a
            onClick={() => setTab('procedures')}
            class={`nav-link ${tab === 'procedures' ? 'active' : ''}`}
          >
            Процедури
          </a>
        </li>
        {companySignal.value?.settings?.expensesEnabled && (
          <li class='nav-item'>
            <a
              onClick={() => setTab('expenses')}
              class={`nav-link ${tab === 'expenses' ? 'active' : ''}`}
            >
              Витрати
            </a>
          </li>
        )}
      </ul>

      {
        {
          sales: <AnalyticsSales year={year} month={month} />,
          procedures: <AnalyticsAppointments year={year} month={month} />,
          expenses: <AnalyticsExpenses year={year} month={month} />,
        }[tab]
      }
    </>
  );
};

export default Analytics;
