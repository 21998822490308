import { useState, useEffect } from 'preact/hooks';
import { route, useRouter } from 'preact-router';

import Loader from '@/components/Loader';
import ProductsTabs from '@/components/ProductsTabs';
import EmptyList from '@/components/EmptyList';
import ChevronRight from '@/svgs/chevron-right.svg';
import { procurementsSignal } from '@/signals';
import { getProcurementsByEntityId } from '@/apis';
import { useExpense, useProduct } from '@/hooks';
import { padStart, procurementsSorter } from '@/utils';
import { Expense } from '@/types';
import ExpensesTabs from '@/components/ExpensesTabs';
import { ProcurementType, Product } from 'cosdb-types';

const ProcurementsIndex = () => {
  const [{ matches }] = useRouter<{ entityType: string; entityId: string }>();
  const { entityType } = matches;
  const { entity: productEntity } = useProduct();
  const { entity: expenseEntity } = useExpense();
  let entity: Expense | Product | null = null;

  if (entityType === 'products') entity = productEntity;
  if (entityType === 'expenses') entity = expenseEntity;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (entity) {
      getProcurementsByEntityId({ entityId: entity.id })
        .then((procurements) => {
          procurementsSignal.value = procurements;
          setLoading(false);
        })
        .catch(() => {
          alert('Не вдалося дістати інформацію про закупівлі.');
          setLoading(false);
        });
    }

    return () => {
      procurementsSignal.value = [];
    };
  }, []);

  const type =
    (entityType === 'products' && ProcurementType.PRODUCT_FOR_SALE) ||
    (entityType === 'expenses' && ProcurementType.EXPENSE) ||
    // product for sale is default
    ProcurementType.PRODUCT_FOR_SALE;

  return (
    <>
      {entityType === 'products' && <ProductsTabs />}
      {entityType === 'expenses' && <ExpensesTabs />}

      {loading && <Loader />}

      {!loading && entity !== null && (
        <>
          <a
            class='btn btn-sm btn-primary mb-2'
            href={`/procurements/new2?entityId=${
              entity.id
            }&type=${type}&entityType=${entityType}&back=${encodeURIComponent(
              `/${entityType}/${entity.id}/procurements`,
            )}`}
          >
            {entityType === 'expenses' && 'Нова витрата'}
            {entityType === 'products' && 'Нова закупівля'}
          </a>

          {procurementsSignal.value.length > 0 && (
            <ul class='list-group'>
              {[...procurementsSignal.value]
                .sort(procurementsSorter)
                .map((a) => {
                  return (
                    <li
                      key={a.id}
                      onClick={() =>
                        route(
                          `/procurements/${a.id}/edit2?back=${`/${entityType}/${
                            entity!.id
                          }/procurements`}`,
                        )
                      }
                      class='list-group-item p-2 d-flex justify-content-between align-items-center'
                    >
                      <div class='text-truncate'>
                        {`${padStart(a.day.toString())}.${padStart(
                          (a.month + 1).toString(),
                        )}.${a.year} / ${a.amount} од. по ціні ${
                          a.price
                        } грн за од.`}
                      </div>
                      <ChevronRight style={{ flexShrink: 0 }} class='ms-2' />
                    </li>
                  );
                })}
            </ul>
          )}

          {procurementsSignal.value.length === 0 && <EmptyList />}
        </>
      )}
    </>
  );
};

export default ProcurementsIndex;
