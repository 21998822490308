import { route } from 'preact-router';

import { clientsSignal } from '@/signals';
import ClientsFilterableList from '@/components/ClientsFilterableList';
import { getMonth, getFullYear } from '@/utils';

const ClientsIndex = () => {
  const active = clientsSignal.value.filter((c) => !c.deleted);
  const totalCount = active.length;
  const newCount = active.filter((client) => {
    const createdAt = client.createdAt.toDate();
    const thisMonth =
      createdAt.getMonth() === getMonth() &&
      createdAt.getFullYear() === getFullYear();

    if (thisMonth) {
      return true;
    }

    return false;
  }).length;

  return (
    <>
      <div class='mb-2 d-flex justify-content-between align-items-center'>
        <a
          class='btn btn-sm btn-primary'
          href={`/clients/new?back=${encodeURIComponent('/clients')}`}
        >
          Новий клієнт
        </a>
        <i class='text-secondary'>{`Нових цього місяця - ${newCount}`}</i>
      </div>

      <h5 class='my-3'>{`Клієнти (${totalCount}):`}</h5>

      <ClientsFilterableList
        showChevron
        onSelect={(c) =>
          route(`/clients/${c.id}/edit?back=${encodeURIComponent('/clients')}`)
        }
      />
    </>
  );
};

export default ClientsIndex;
