import * as db from 'firebase/firestore';
import { b } from './_common';
import { DEFAULT_ERROR } from '@/constants';
import { Company } from 'cosdb-types';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const getCompany = () =>
  db
    .getDoc(db.doc(...b()))
    .then((r) => r.data() as Company)
    .catch((e) => {
      logEvent(AnalyticsEvent.GetCompanyError, { message: e.message });

      throw e;
    });

export const updateCompany = (
  company: Partial<Pick<Company, 'settings' | 'featureFlags' | 'fcmTokens'>>,
) =>
  db
    .updateDoc(db.doc(...b()), company)
    .catch(() => alert(DEFAULT_ERROR))
    .catch((e) => {
      logEvent(AnalyticsEvent.UpdateCompanyError, { message: e.message });

      throw e;
    });
