import { useState } from 'preact/hooks';
import { useRouter, route } from 'preact-router';

import ClientsForm from '@/components/ClientsForm';
import ClientsTabs from '@/components/ClientsTabs';
import { useClient } from '@/hooks';
import { validatePhoneNumber } from '@/signals';
import { updateClient } from '@/apis';
import Loader from '@/components/Loader';
import { RequestStatus } from '@/enums';
import { Client } from '@/types';
import { DEFAULT_ERROR } from '@/constants';

const ClientsEdit = () => {
  const [{ matches }] = useRouter();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);
  const { entity } = useClient();

  const onSubmit = (properties: Client) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    updateClient(properties.id, properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  if (!entity) return <Loader />;

  return (
    <>
      <ClientsTabs />

      <ClientsForm
        onChange={{
          phoneNumber: (e) => validatePhoneNumber(e as InputEvent, entity.id),
        }}
        onSubmit={onSubmit}
        entity={entity}
        requestStatus={requestStatus}
      />
    </>
  );
};

export default ClientsEdit;
