import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';

import { appointmentsSignal, proceduresSignal } from '@/signals';
import { getAppointmentsByClientId } from '@/apis';
import { useClient } from '@/hooks';
import ChevronRight from '@/svgs/chevron-right.svg';
import ClientsTabs from '@/components/ClientsTabs';
import Loader from '@/components/Loader';
import EmptyList from '@/components/EmptyList';
import { padStart, slotToString, appointmentsSorter } from '@/utils';
import { Appointment } from '@/types';
import { AppointmentStatus } from '@/enums';
import classNames from 'classnames';

const ClientsAppointments = () => {
  const { entity } = useClient();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (entity) {
      getAppointmentsByClientId({ clientId: entity.id })
        .then((appointments) => {
          appointmentsSignal.value = appointments;
          setLoading(false);
        })
        .catch(() => {
          alert(
            'Сталася помилка при діставанні інформації про записи. Спробуйте пізніше.',
          );
          setLoading(false);
        });
    }

    return () => {
      appointmentsSignal.value = [];
    };
  }, []);

  const proceduresByAppointment = (a: Appointment) =>
    proceduresSignal.value.filter((p) => a.procedureId.includes(p.id));

  const price2 = (a: Appointment) =>
    Object.values(a.price2).reduce((acc, p) => acc + p, 0);

  return (
    <>
      <ClientsTabs />

      {loading && <Loader />}

      {!loading && entity && (
        <>
          <a
            class='btn btn-sm btn-primary mb-2'
            href={`/appointments/new?clientId=${
              entity.id
            }&back=${encodeURIComponent(`/clients/${entity.id}/appointments`)}`}
          >
            Новий запис
          </a>

          {appointmentsSignal.value.length > 0 && (
            <ul class='list-group'>
              {[...appointmentsSignal.value]
                .sort(appointmentsSorter)
                .map((a) => {
                  return (
                    <li
                      key={a.id}
                      onClick={() =>
                        route(
                          `/appointments/${a.id}/edit?back=${encodeURIComponent(
                            `/clients/${entity.id}/appointments`,
                          )}`,
                        )
                      }
                      class={classNames(
                        'list-group-item p-2 d-flex justify-content-between align-items-center',
                        {
                          'bg-danger bg-opacity-25':
                            a.status === AppointmentStatus.CANCELLED,
                        },
                      )}
                    >
                      <div class='text-truncate'>{`${padStart(
                        a.day.toString(),
                      )}.${padStart((a.month + 1).toString())}.${
                        a.year
                      } @ ${slotToString(a.slot)} / ${proceduresByAppointment(a)
                        .map((p) => p.name)
                        .join(', ')} / ${price2(a)} грн`}</div>
                      <ChevronRight style={{ flexShrink: 0 }} class='ms-2' />
                    </li>
                  );
                })}
            </ul>
          )}

          {appointmentsSignal.value.length === 0 && <EmptyList />}
        </>
      )}
    </>
  );
};

export default ClientsAppointments;
