import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';

import { auth } from '@/firebase';
import { userSignal } from '@/signals';
import Loader from '@/components/Loader';
import { isAppInitialized } from '@/signals/isAppInitialized';

const provider = new GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');

onAuthStateChanged(auth, async (user) => {
  if (!user) {
    userSignal.value = null;
    return;
  }

  userSignal.value = user;
});

const Auth = () => {
  if (userSignal.value === undefined) return <Loader />;
  if (userSignal.value && !isAppInitialized()) return <Loader />;

  const onSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const email = formData.get('email') as string | null;
    const password = formData.get('password') as string | null;

    if (!email || !password) {
      alert('Не введено імейл та/або пароль.');
      return;
    }

    return signInWithEmailAndPassword(auth, email, password).catch(() =>
      alert('Упс, не вдалося.'),
    );
  };

  return (
    <>
      <h3 class='mb-3 mt-3'>
        Можна залогінитися
        <br />з Google:
      </h3>
      <div class='mb-4'>
        <button
          type='button'
          class='btn btn-primary'
          onClick={() =>
            process.env.PREACT_MODE === 'dev'
              ? signInWithPopup(auth, provider)
              : signInWithRedirect(auth, provider)
          }
        >
          Залогінитися з Google
        </button>
      </div>
      <hr />
      <h3 class='mb-3 mt-3'>
        Можна залогінитися
        <br />з імейлом/паролем:
      </h3>
      <form onSubmit={onSubmit}>
        <div class='mb-2'>
          <label for='email' class='form-label'>
            Імейл
          </label>
          <input name='email' type='email' class='form-control' id='email' />
        </div>
        <div class='mb-3'>
          <label for='password' class='form-label'>
            Пароль
          </label>
          <input
            name='password'
            type='password'
            class='form-control'
            id='password'
          />
        </div>
        <button type='submit' class='btn btn-primary'>
          Залогінитися з паролем
        </button>
      </form>
    </>
  );
};

export default Auth;
