import { ComponentChildren } from 'preact';

type Props = {
  children: ComponentChildren;
};

const Secondary = ({ children }: Props) => {
  return (
    <i style={{ fontSize: 14 }} class='text-black-50 text-small'>
      {children}
    </i>
  );
};

export default Secondary;
