import { getToken } from 'firebase/messaging';
import { AnalyticsEvent, logEvent, messaging, vapidKey } from '@/firebase';
import { NOTIFICATIONS_SUBSCRIPTION_ERROR } from '@/constants';
import { companySignal } from '@/signals/company';
import { updateCompany } from './company';

export const updateFcmTokenIfNeeded = async () => {
  const fcmTokens = companySignal.value?.fcmTokens || ([] as string[]);

  try {
    const fcmToken = await getToken(messaging, { vapidKey }).catch(() => null);

    if (
      (fcmToken && !fcmTokens) ||
      (fcmToken && !fcmTokens.includes(fcmToken))
    ) {
      updateCompany({ fcmTokens: [...(fcmTokens || []), fcmToken] }).catch(() =>
        alert(NOTIFICATIONS_SUBSCRIPTION_ERROR),
      );
    }
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.UpdateFcmTokenIfNeededError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
};
