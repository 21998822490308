import { getFullYear } from '@/utils';
import { useState } from 'preact/hooks';

const MIN_YEAR = 1960;

const SimplifiedDatepicker = ({
  day,
  month,
  year,
  required = false,
  noLabel = false,
}: {
  day?: number;
  month?: number;
  year?: number;
  required?: boolean;
  noLabel?: boolean;
}) => {
  const [state] = useState({
    day: day || '',
    month: month || '',
    year: year || '',
  });

  return (
    <div className='d-flex align-items-center'>
      <div class='w-100'>
        {!noLabel && (
          <label class='form-label' htmlFor='day'>
            День
          </label>
        )}
        <select name='day' id='day' className='form-select' required={required}>
          <option />
          {new Array(31)
            .fill(0)
            .map((_, index) => index + 1)
            .map((value) => (
              <option value={value} selected={value === state.day}>
                {value}
              </option>
            ))}
        </select>
      </div>
      <div className='ms-2' />
      <div class='w-100'>
        {!noLabel && (
          <label class='form-label' htmlFor='month'>
            Місяць
          </label>
        )}
        <select
          name='month'
          id='month'
          className='form-select'
          required={required}
        >
          <option />
          {new Array(12)
            .fill(0)
            .map((_, index) => index + 1)
            .map((value) => (
              <option value={value} selected={value === state.month}>
                {value}
              </option>
            ))}
        </select>
      </div>
      <div className='ms-2' />
      <div class='w-100'>
        {!noLabel && (
          <label class='form-label' htmlFor='year'>
            Рік
          </label>
        )}
        <select
          name='year'
          id='year'
          className='form-select'
          required={required}
        >
          <option />
          {new Array(getFullYear() - MIN_YEAR + 1)
            .fill(0)
            .map((_, index) => index + MIN_YEAR)
            .map((value) => (
              <option value={value} selected={value === state.year}>
                {value}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default SimplifiedDatepicker;
