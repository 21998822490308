import { Client } from '@/types';
import * as db from 'firebase/firestore';
import { b } from './_common';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const createClient = async (props: Client) => {
  await db
    .setDoc(db.doc(...b(), 'clients', props.id), props, { merge: true })
    .catch((e) => {
      logEvent(AnalyticsEvent.CreateClientError, { message: e.message });

      throw e;
    });
};

export const updateClient = async (id: string, props: Partial<Client>) => {
  await db.updateDoc(db.doc(...b(), 'clients', id), props).catch((e) => {
    logEvent(AnalyticsEvent.UpdateClientError, { message: e.message });

    throw e;
  });
};
