import type { ComponentChildren } from 'preact';
import { useEffect } from 'preact/hooks';
import cn from 'classnames';

import { appointmentsSignal } from '@/signals';
import { getDaysInMonth, getMonthOffset, groupBy, mergeRanges } from '@/utils';
import { getWorkDays, getAppointmentsByYearAndMonth } from '@/apis';
import Timeslots from '@/components/Timeslots';
import CalendarHeader from '@/components/Calendar/Header';

import '@/components/Calendar/index.css';
import { Appointment, Day, Slot, WorkDays } from '@/types';
import { AppointmentStatus, Mode } from '@/enums';
import { workDaysSignal } from '@/signals/workdays';
import { companySignal } from '@/signals/company';

type ActiveDay = number | null;

type Props = {
  month: number;
  year: number;
  onChange: (m: number, y: number) => void;
  onSlotClick?: (slot: Slot) => void;
  children?: ComponentChildren;
  activeDay: ActiveDay;
  setActiveDay: (a: ActiveDay) => void;
  onClick: (a: number) => void;
  filteredAppointments?: string[];
  onAppointmentClick?: (a: Appointment) => void;
  isDayDisabled: (a: ActiveDay, b: WorkDays) => boolean;
  mode: Mode;
};

const ButtonBackground = ({ slots }: { slots: Slot[] }) => {
  if (!slots) return null;

  return (
    <div style={{ position: 'absolute', inset: 0, zIndex: 0 }}>
      {mergeRanges(slots).map(([s, e]) => {
        const top =
          ((s - (companySignal.value?.settings?.startTime || 540)) / 690) * 100;
        const height = ((e - s) / 690) * 100;

        return (
          <div
            key={`${top},${height}`}
            style={{
              background: 'rgba(0, 100, 0, 0.25)',
              position: 'absolute',
              left: 0,
              right: 0,
              top: `${top}%`,
              height: `${height}%`,
            }}
          />
        );
      })}
    </div>
  );
};

const Calendar = ({
  month,
  year,
  onChange,
  mode,
  activeDay,
  setActiveDay,
  onClick,
  filteredAppointments = [],
  onAppointmentClick,
  onSlotClick,
  children,
  isDayDisabled,
}: Props) => {
  useEffect(() => {
    Promise.all([
      getWorkDays(`${year}:${month}`),
      getAppointmentsByYearAndMonth({ year, month }),
    ])
      .then(([_workDays, appointments]) => {
        appointmentsSignal.value = appointments.filter(
          (a) => a.status !== AppointmentStatus.CANCELLED,
        );

        if (_workDays) {
          workDaysSignal.value = _workDays;
        } else {
          workDaysSignal.value = {};
        }
      })
      .catch(() =>
        alert(
          'Сталася помилка при витягуванні записів та/або робочих днів. Спробуйте пізніше.',
        ),
      );
  }, [month, year]);

  const dList = new Array(getDaysInMonth(year, month)).fill(null);

  const getClassName = (d: number) => {
    if (mode !== Mode.SET_DAYS && d === activeDay) {
      return 'btn-primary';
    }

    if (mode === Mode.SET_DAYS && workDaysSignal.value[d as Day]) {
      return 'btn-primary';
    }

    if (mode !== Mode.SET_DAYS && workDaysSignal.value[d as Day]) {
      return 'btn-outline-primary';
    }

    return 'btn-light';
  };

  const appointmentsByDay = groupBy(appointmentsSignal.value, 'day');

  return (
    <>
      <CalendarHeader
        month={month}
        year={year}
        onChange={(m, y) => {
          setActiveDay(null);
          workDaysSignal.value = {};

          onChange(m, y);
        }}
      />

      <div className='day-names mb-2'>
        {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'].map((s) => (
          <span style={{ fontSize: '.8rem' }} class='text-center opacity-50'>
            {s}
          </span>
        ))}
      </div>

      <div class='days-list mb-2'>
        {dList.map((i, index) => {
          const d = index + 1;

          return (
            <button
              disabled={isDayDisabled(d, workDaysSignal.value)}
              type='button'
              key={`${d}-${month}-${year}`}
              style={
                index > 0
                  ? { position: 'relative' }
                  : {
                      gridColumnStart: getMonthOffset(year, month)! + 1,
                      position: 'relative',
                    }
              }
              className={cn('btn btn-sm', getClassName(d))}
              onClick={() => onClick(d)}
            >
              {appointmentsByDay[d]?.length && activeDay !== d && (
                <ButtonBackground
                  slots={appointmentsByDay[d].map(
                    (a) => [a.slot[0], a.slot[0] + a.duration] as Slot,
                  )}
                />
              )}
              <span style={{ zIndex: 1 }}>{d}</span>
            </button>
          );
        })}
      </div>

      {children}

      {onSlotClick &&
        onAppointmentClick &&
        !isDayDisabled(activeDay, workDaysSignal.value) &&
        mode !== Mode.SET_DAYS && (
          <Timeslots
            mode={mode}
            filteredAppointments={filteredAppointments}
            activeDay={activeDay}
            month={month}
            year={year}
            onSlotClick={onSlotClick}
            onAppointmentClick={onAppointmentClick}
          />
        )}
    </>
  );
};

export default Calendar;
