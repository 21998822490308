import { useState, useEffect } from 'preact/hooks';
import { getProcurementsByYearAndMonth } from '@/apis';
import { groupBy, sumBy, procurementsSorter } from '@/utils';
import { productsSignal, expensesSignal, brandNameById } from '@/signals';
import EmptyList from '@/components/EmptyList';
import Loader from '@/components/Loader';
import { Procurement, ProcurementType } from 'cosdb-types';

const AnalyticsExpenses = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  const [procurements, setProcurements] = useState<Procurement[] | null>(null);

  useEffect(() => {
    setProcurements(null);
    getProcurementsByYearAndMonth({ year, month })
      .then((_procurements) => {
        setProcurements([..._procurements].sort(procurementsSorter));
      })
      .catch(() =>
        alert(
          'Не вдалося витягнути список закупівель згідно крітеріїв. Спробуйте пізніше.',
        ),
      );
  }, [month, year]);

  const procurementsProductsForSale = procurements?.filter((p) => {
    if (p.type === ProcurementType.PRODUCT_FOR_SALE || !p.type) {
      const product = productsSignal.value.find(
        (prod) => prod.id === p.entityId,
      );

      if (!product) return false;
      if (product.deleted) return false;
      return true;
    }

    return false;
  });

  const procurementsProductsForWork = procurements?.filter((p) => {
    if (p.type === ProcurementType.PRODUCT_FOR_WORK) {
      const product = productsSignal.value.find(
        (prod) => prod.id === p.entityId,
      );

      if (!product) return false;
      if (product.deleted) return false;
      return true;
    }

    return false;
  });

  const procurementsExpenses = procurements?.filter((p) => {
    if (p.type === ProcurementType.EXPENSE) {
      const expense = expensesSignal.value.find((e) => e.id === p.entityId);

      if (!expense) return false;
      return true;
    }

    return false;
  });

  return (
    <>
      <h5 class='mt-4'>Інші витрати:</h5>
      {procurements === null && <Loader />}
      {procurementsExpenses && procurementsExpenses.length === 0 && (
        <EmptyList />
      )}
      {procurementsExpenses && procurementsExpenses.length > 0 && (
        <table
          style={{ fontSize: '.85rem' }}
          class='table table-striped table-sm table-bordered'
        >
          <thead>
            <tr>
              <th>Назва</th>
              <th style={{ width: '15%' }}>Кільк.</th>
              <th style={{ width: '15%' }}>Соб.</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupBy(procurementsExpenses, 'entityId')).map(
              ([entityId, _procurements], index) => {
                const expense = expensesSignal.value.find(
                  (e) => e.id === entityId,
                );

                return (
                  <tr>
                    <td data-locator={`expenses-row-${index}-name`}>
                      {expense?.name}
                    </td>
                    <td data-locator={`expenses-row-${index}-count`}>
                      {sumBy(_procurements, 'amount').toFixed()}
                    </td>
                    <td data-locator={`expenses-row-${index}-price`}>
                      {_procurements
                        .reduce((acc, i) => {
                          acc = acc + i.amount * i.price;
                          return acc;
                        }, 0)
                        .toFixed()}
                    </td>
                  </tr>
                );
              },
            )}

            <tr>
              <td>&nbsp;</td>
              <td data-locator='expenses-total-count'>
                {sumBy(procurementsExpenses, 'amount').toFixed()}
              </td>
              <td data-locator='expenses-total-price'>
                {procurementsExpenses
                  .reduce((acc, i) => {
                    acc = acc + i.amount * i.price;
                    return acc;
                  }, 0)
                  .toFixed()}
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <h5 class='mt-4'>На продаж:</h5>
      {procurements === null && <Loader />}
      {procurementsProductsForSale &&
        procurementsProductsForSale.length === 0 && <EmptyList />}
      {procurementsProductsForSale &&
        procurementsProductsForSale.length > 0 && (
          <table
            style={{ fontSize: '.85rem' }}
            class='table table-striped table-sm table-bordered'
          >
            <thead>
              <tr>
                <th>Назва</th>
                <th style={{ width: '15%' }}>Кільк.</th>
                <th style={{ width: '15%' }}>Соб.</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(
                groupBy(procurementsProductsForSale, 'entityId'),
              ).map(([entityId, _procurements], index) => {
                const product = productsSignal.value.find(
                  (prod) => prod.id === entityId,
                );

                return (
                  <tr>
                    <td data-locator={`for-sale-row-${index}-name`}>
                      <b>{brandNameById(product?.brandId)}</b>
                      {' - '}
                      {product?.name}
                    </td>
                    <td data-locator={`for-sale-row-${index}-count`}>
                      {sumBy(_procurements, 'amount').toFixed()}
                    </td>
                    <td data-locator={`for-sale-row-${index}-price`}>
                      {_procurements
                        .reduce((acc, i) => {
                          acc = acc + i.amount * i.price;
                          return acc;
                        }, 0)
                        .toFixed()}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td>&nbsp;</td>
                <td data-locator='for-sale-total-count'>
                  {sumBy(procurementsProductsForSale, 'amount').toFixed()}
                </td>
                <td data-locator='for-sale-total-price'>
                  {procurementsProductsForSale
                    .reduce((acc, i) => {
                      acc = acc + i.amount * i.price;
                      return acc;
                    }, 0)
                    .toFixed()}
                </td>
              </tr>
            </tbody>
          </table>
        )}

      <h5 class='mt-4'>Для роботи:</h5>
      {procurements === null && <Loader />}
      {procurementsProductsForWork &&
        procurementsProductsForWork.length === 0 && <EmptyList />}
      {procurementsProductsForWork &&
        procurementsProductsForWork.length > 0 && (
          <table
            style={{ fontSize: '.85rem' }}
            class='table table-striped table-sm table-bordered'
          >
            <thead>
              <tr>
                <th>Назва</th>
                <th style={{ width: '15%' }}>Кільк.</th>
                <th style={{ width: '15%' }}>Соб.</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(
                groupBy(procurementsProductsForWork, 'entityId'),
              ).map(([entityId, _procurements], index) => {
                const product = productsSignal.value.find(
                  (prod) => prod.id === entityId,
                );

                return (
                  <tr>
                    <td data-locator={`for-work-row-${index}-name`}>
                      <b>{brandNameById(product?.brandId)}</b>
                      {' - '}
                      {product?.name}
                    </td>
                    <td data-locator={`for-work-row-${index}-count`}>
                      {sumBy(_procurements, 'amount').toFixed()}
                    </td>
                    <td data-locator={`for-work-row-${index}-price`}>
                      {_procurements
                        .reduce((acc, i) => {
                          acc = acc + i.amount * i.price;
                          return acc;
                        }, 0)
                        .toFixed()}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td>&nbsp;</td>
                <td data-locator='for-work-total-count'>
                  {sumBy(procurementsProductsForWork, 'amount').toFixed()}
                </td>
                <td data-locator='for-work-total-price'>
                  {procurementsProductsForWork
                    .reduce((acc, i) => {
                      acc = acc + i.amount * i.price;
                      return acc;
                    }, 0)
                    .toFixed()}
                </td>
              </tr>
            </tbody>
          </table>
        )}
    </>
  );
};

export default AnalyticsExpenses;
