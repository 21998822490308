import { FirestoreWorkDays, Slot, WorkDays } from '@/types';
import * as db from 'firebase/firestore';
import { b } from './_common';
import { RESOLUTION } from '@/constants';
import { mergeRanges } from '@/utils';
import { AnalyticsEvent, logEvent } from '@/firebase';

const parseSlots = (slots: string | string[]): Slot[] => {
  let res: Slot[] = [];

  if (typeof slots !== 'string') {
    res = slots.map((slot) => {
      const [hours, minutes] = slot.split(':');
      const b = +hours * 60 + +minutes;

      return [b, b + RESOLUTION];
    });
  } else {
    res = JSON.parse(slots) as Slot[];
  }

  if (res.length === 0) return [];
  return mergeRanges(res);
};

const workDaysFromFirestore = (workDay: FirestoreWorkDays): WorkDays => {
  return Object.fromEntries(
    Object.entries(workDay).map(([day, slots = '[]']) => [
      day,
      parseSlots(slots as string | string[]),
    ]),
  );
};

const workDaysToFirestore = (workDay: WorkDays) => {
  return Object.fromEntries(
    Object.entries(workDay).map(([day, slots]) => [day, JSON.stringify(slots)]),
  );
};

export const getWorkDays = async (id: string) => {
  try {
    const snap = await db.getDoc(db.doc(...b(), 'work-days', id));

    if (snap.exists()) return workDaysFromFirestore(snap.data());
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.GetWorkDaysError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
  return null;
};

export const createOrUpdateWorkDays = (id: string, props: WorkDays) =>
  db
    .setDoc(db.doc(...b(), 'work-days', id), workDaysToFirestore(props))
    .catch((e) => {
      logEvent(AnalyticsEvent.CreateOrUpdateWorkDaysError, {
        message: (e as { message: string }).message,
      });

      return Promise.reject(e);
    });
