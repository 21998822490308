import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';

import ClientsForm from '@/components/ClientsForm';
import ClientsTabs from '@/components/ClientsTabs';
import { validatePhoneNumber } from '@/signals';
import { createClient } from '@/apis';
import { RequestStatus } from '@/enums';
import { Client } from '@/types';
import { DEFAULT_ERROR } from '@/constants';

const ClientsNew = () => {
  const [{ matches }] = useRouter();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Client) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    createClient(properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(
          () =>
            route(
              matches?.back?.replace(
                '&clientId=PLACEHOLDER',
                `&clientId=${properties.id}`,
              ) || '/',
            ),
          1000,
        );
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  return (
    <>
      <ClientsTabs />
      <ClientsForm
        onChange={{
          phoneNumber: (e) => validatePhoneNumber(e as InputEvent, 'new'),
        }}
        onSubmit={onSubmit}
        requestStatus={requestStatus}
      />
    </>
  );
};

export default ClientsNew;
