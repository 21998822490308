import { RequestStatus } from '@/enums';
import CheckLg from '@/svgs/check-lg.svg';

type Props = {
  requestStatus: RequestStatus;
  disabled?: boolean;
  text: string;
};

const ButtonSubmit = ({ requestStatus, disabled = false, text }: Props) => {
  return (
    <button
      type='submit'
      class='btn btn-primary'
      disabled={requestStatus === RequestStatus.IN_PROGRESS || disabled}
    >
      {`${text} `}
      {requestStatus === RequestStatus.IN_PROGRESS && (
        <span
          class='spinner-border spinner-border-sm'
          role='status'
          aria-hidden='true'
        />
      )}
      {requestStatus === RequestStatus.IS_DONE && <CheckLg />}
    </button>
  );
};

export default ButtonSubmit;
