import { Router, Route } from 'preact-router';
import { render } from 'preact';

import Home from '@/pages/Home';
import Auth from '@/pages/Auth';
import Analytics from '@/pages/Analytics';
import Settings from '@/pages/Settings';

import ClientsIndex from '@/pages/ClientsIndex';
import ClientsEdit from '@/pages/ClientsEdit';
import ClientsNew from '@/pages/ClientsNew';
import ClientsAppointments from '@/pages/ClientsAppointments';
import ClientsSales from '@/pages/ClientsSales';

import ProceduresIndex from '@/pages/ProceduresIndex';
import ProceduresEdit from '@/pages/ProceduresEdit';
import ProceduresNew from '@/pages/ProceduresNew';

import ExpensesIndex from '@/pages/ExpensesIndex';
import ExpensesEdit from '@/pages/ExpensesEdit';
import ExpensesNew from '@/pages/ExpensesNew';

import AppointmentsEdit from '@/pages/AppointmentsEdit';
import AppointmentsNew from '@/pages/AppointmentsNew';

import ProductsIndex from '@/pages/ProductsIndex';
import ProductsBrands from '@/pages/ProductsBrands';
import ProductsNew from '@/pages/ProductsNew';
import ProcurementsIndex from '@/pages/ProcurementsIndex';
import ProductsEdit from '@/pages/ProductsEdit';

import SalesNew from '@/pages/SalesNew';
import SalesEdit from '@/pages/SalesEdit';

import ProcurementsNew from '@/pages/ProcurementsNew';
import ProcurementsEdit from '@/pages/ProcurementsEdit';
import ProductsSales from '@/pages/ProductsSales';

import Calendar4Week from '@/svgs/calendar4-week.svg';
import GraphUp from '@/svgs/graph-up.svg';
import Basket from '@/svgs/basket.svg';
import Gear from '@/svgs/gear.svg';
import People from '@/svgs/people.svg';
import CashStack from '@/svgs/cash-stack.svg';
import LayoutTextSidebarReverse from '@/svgs/layout-text-sidebar-reverse.svg';

import {
  brandsSignal,
  clientsSignal,
  expensesSignal,
  proceduresSignal,
  productsSignal,
  userSignal,
} from '@/signals';

import '@/index.css';
import { useEffect, useState } from 'preact/hooks';
import { subscribeToOrdersByCompanyId } from './apis/subscribeToOrders';
import { subscribeToProductsByCompanyId } from './apis/subscribeToProducts';
import { ordersSignal } from './signals/orders';
import { subscribeToBrands } from './apis/subscribeToBrands';
import { subscribeToClients } from './apis/subscribeToClients';
import { subscribeToProcedures } from './apis/subscribeToProcedures';
import { subscribeToExpenses } from './apis/subscribeToExpenses';
import Loader from './components/Loader';
import { isAppInitialized } from './signals/isAppInitialized';
import { subscribeToCompany } from './apis/subscribeToCompany';
import { companySignal } from './signals/company';
import { AnalyticsEvent, logEvent } from './firebase';

const ProductsRouter = ({ matches }: { matches: Record<string, string> }) => {
  if (matches.brand) return <ProductsIndex />;
  return <ProductsBrands />;
};

const App = () => {
  const [proceduresReady, setProceduresReady] = useState(false);
  const [expensesReady, setExpensesReady] = useState(false);
  const [brandsReady, setBrandsReady] = useState(false);
  const [clientsReady, setClientsReady] = useState(false);
  const [ordersReady, setOrdersReady] = useState(false);
  const [productsReady, setProductsReady] = useState(false);
  const [companyReady, setCompanyReady] = useState(false);

  useEffect(() => {
    logEvent(AnalyticsEvent.AppLoaded, { time: Math.round(performance.now()) });
  }, []);

  useEffect(() => {
    if (!userSignal.value?.uid) {
      return;
    }

    const unsubscribeCompany = subscribeToCompany(
      { companyId: userSignal.value.uid },
      (company) => {
        setCompanyReady(true);
        companySignal.value = company;
      },
    );

    const unsubscribeProcedures = subscribeToProcedures(
      { companyId: userSignal.value.uid },
      (procedures) => {
        setProceduresReady(true);
        proceduresSignal.value = procedures;
      },
    );

    const unsubscribeExpenses = subscribeToExpenses(
      { companyId: userSignal.value.uid },
      (expenses) => {
        setExpensesReady(true);
        expensesSignal.value = expenses;
      },
    );

    const unsubscribeBrands = subscribeToBrands(
      { companyId: userSignal.value.uid },
      (brands) => {
        setBrandsReady(true);
        brandsSignal.value = brands;
      },
    );

    const unsubscribeClients = subscribeToClients(
      { companyId: userSignal.value.uid },
      (clients) => {
        setClientsReady(true);
        clientsSignal.value = clients;
      },
    );

    const unsubscribeOrders = subscribeToOrdersByCompanyId(
      { companyId: userSignal.value.uid },
      (orders) => {
        setOrdersReady(true);
        ordersSignal.value = orders;
      },
    );

    const unsubscribeProducts = subscribeToProductsByCompanyId(
      { companyId: userSignal.value.uid },
      (products) => {
        setProductsReady(true);
        productsSignal.value = products.filter((p) => !p.deleted);
      },
    );

    return () => {
      unsubscribeBrands();
      unsubscribeOrders();
      unsubscribeProducts();
      unsubscribeClients();
      unsubscribeProcedures();
      unsubscribeExpenses();
      unsubscribeCompany();
    };
  }, [userSignal.value?.uid]);

  const appOperational =
    proceduresReady &&
    expensesReady &&
    brandsReady &&
    clientsReady &&
    ordersReady &&
    productsReady &&
    companyReady;

  useEffect(() => {
    if (appOperational) {
      logEvent(AnalyticsEvent.AppOperational, {
        time: Math.round(performance.now()),
      });
    }
  }, [appOperational]);

  if (!isAppInitialized()) {
    return (
      <div class='App pb-5'>
        <Auth />
      </div>
    );
  }

  if (!appOperational) {
    return (
      <div class='App pb-5'>
        <Loader />
      </div>
    );
  }

  const navButtonClass = 'btn btn-sm btn-outline-secondary';

  return (
    <div class='App pb-5'>
      <div class='TapBar'>
        <a href='/' data-locator='home' class={navButtonClass}>
          <Calendar4Week alt='Дім' width='20' />
        </a>
        {companySignal.value?.settings?.analyticsEnabled && (
          <a data-locator='analytics' class={navButtonClass} href='/analytics'>
            <GraphUp alt='Аналітика' width='20' />
          </a>
        )}
        <span class='m-auto' />
        {companySignal.value?.settings?.expensesEnabled && (
          <a data-locator='expenses' class={navButtonClass} href='/expenses'>
            <CashStack alt='Витрати' width='20' />
          </a>
        )}
        <a data-locator='clients' class={navButtonClass} href='/clients'>
          <People alt='Клієнти' width='20' />
        </a>
        <a data-locator='procedures' class={navButtonClass} href='/procedures'>
          <LayoutTextSidebarReverse alt='Процедури' width='20' />
        </a>
        {companySignal.value?.settings?.productsEnabled && (
          <a data-locator='products' class={navButtonClass} href='/products'>
            <Basket alt='Продукти' width='20' />
          </a>
        )}
        <a data-locator='settings' class={navButtonClass} href='/settings'>
          <Gear alt='Налаштування' width='20' />
        </a>
      </div>

      <Router>
        <Route path='/' component={Home} />
        <Route path='/settings' component={Settings} />
        <Route path='/analytics' component={Analytics} />

        <Route
          path='/:entityType/:entityId/procurements'
          component={ProcurementsIndex}
        />
        <Route path='/procurements/new2' component={ProcurementsNew} />
        <Route
          path='/procurements/:procurementId/edit2'
          component={ProcurementsEdit}
        />

        <Route path='/clients' component={ClientsIndex} />
        <Route path='/clients/new' component={ClientsNew} />
        <Route
          path='/clients/:clientId/appointments'
          component={ClientsAppointments}
        />
        <Route path='/clients/:clientId/sales' component={ClientsSales} />
        <Route path='/clients/:clientId/edit' component={ClientsEdit} />

        <Route path='/expenses' component={ExpensesIndex} />
        <Route path='/expenses/new' component={ExpensesNew} />
        <Route path='/expenses/:expenseId/edit' component={ExpensesEdit} />

        <Route path='/procedures' component={ProceduresIndex} />
        <Route path='/procedures/new' component={ProceduresNew} />
        <Route
          path='/procedures/:procedureId/edit'
          component={ProceduresEdit}
        />

        <Route path='/appointments/new' component={AppointmentsNew} />
        <Route
          path='/appointments/:appointmentId/edit'
          component={AppointmentsEdit}
        />

        <Route path='/products' component={ProductsRouter} />
        <Route path='/products/new' component={ProductsNew} />
        <Route path='/products/:productId/edit' component={ProductsEdit} />
        <Route path='/products/:productId/sales' component={ProductsSales} />

        <Route path='/sales/new' component={SalesNew} />
        <Route path='/sales/:saleId/edit' component={SalesEdit} />
      </Router>
    </div>
  );
};

render(<App />, document.querySelector('.root')!);
