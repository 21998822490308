import { useState } from 'preact/hooks';
import { useRouter } from 'preact-router';

import ArrowRight from '@/svgs/arrow-right.svg';
import { padStart, proceduresSorter, slotToString } from '@/utils';
import {
  buildNewAppointment,
  clientsSignal,
  proceduresSignal,
} from '@/signals';
import Calendar from '@/components/Calendar';
import ButtonSubmit from '@/components/ButtonSubmit';
import ComplexFormSection from '@/components/ComplexFormSection';
import ComplexFormSectionHeading from '@/components/ComplexFormSectionHeading';
import InputComplex, { InputComplexType } from '@/components/InputComplex';
import ClientsFilterableList from '@/components/ClientsFilterableList';
import { Appointment, Day, Slot } from '@/types';
import { AppointmentStatus, Mode, PaymentStatus, RequestStatus } from '@/enums';
import Secondary from './Secondary';
import { NumberInput } from './NumberInput';

type Props = {
  onSubmit: (a: Appointment) => void;
  entity?: Appointment;
  requestStatus: RequestStatus;
};

type Matches = Partial<Record<keyof Appointment | 'back', string | undefined>>;

const AppointmentsForm = ({ onSubmit, entity, requestStatus }: Props) => {
  const [{ matches, url }] = useRouter<Matches>();
  const { back } = matches;
  const model = entity || buildNewAppointment({}, matches);
  const defaultState = { ...model, step: 0 };

  type StateType = typeof defaultState;

  const [state, _setState] = useState<StateType>(defaultState);

  const setState = (args: Partial<StateType>) =>
    _setState((s) => ({ ...s, ...args }));

  const everythingIsInPlace =
    state.clientId && state.procedureId.length > 0 && state.day && state.slot;

  const selectedClient = clientsSignal.value.find(
    (_c) => _c.id === state.clientId,
  );

  const onDayClick = (_d: number) => setState({ day: _d });

  const onTimeClick = (slot: Slot) => setState({ slot, step: 0 });

  const editUserBack = encodeURIComponent(
    [
      url.match('/edit')
        ? `/appointments/${state.id}/edit`
        : '/appointments/new',
      back ? `?back=${encodeURIComponent(back)}` : '',
    ].join(''),
  );
  const newClientBack = encodeURIComponent(
    [
      url.match('/edit')
        ? `/appointments/${state.id}/edit`
        : '/appointments/new',
      `?day=${state.day || ''}`,
      `&month=${state.month || ''}`,
      `&year=${state.year || ''}`,
      `&slot=${JSON.stringify(state.slot || '')}`,
      `&procedureId=${btoa(JSON.stringify(state.procedureId || ''))}`,
      `&price1=${btoa(JSON.stringify(state.price1 || ''))}`,
      `&price2=${btoa(JSON.stringify(state.price2 || ''))}`,
      `&notes=${state.notes || ''}`,
      `&duration=${state.duration || ''}`,
      '&clientId=PLACEHOLDER',
      back ? `&back=${encodeURIComponent(back)}` : '',
    ].join(''),
  );

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());

    const normalized = {
      clientId: entries.clientId.toString(),
      procedureId: formData.getAll('procedureId').map((pid) => pid.toString()),
      year: +entries.year,
      duration: +entries.duration,
      month: +entries.month,
      day: +entries.day,
      slot: JSON.parse(entries.slot.toString()) as Slot,
      price1: state.price1,
      price2: state.price2,
      id: entries.id.toString(),
      notes: entries.notes.toString(),
      status: state.status,
      paymentStatus: state.paymentStatus,
      tips: +entries.tips,
    };

    onSubmit(normalized);
  };

  const sum = (acc: number, i: number) => acc + i;

  const calculatedPrice2 = state.procedureId
    .map((p) => proceduresSignal.value.find((_p) => _p.id === p)?.price2 || 0)
    .reduce(sum, 0);

  const calculatedPrice1 = state.procedureId
    .map((p) => proceduresSignal.value.find((_p) => _p.id === p)?.price1 || 0)
    .reduce(sum, 0);

  return (
    <>
      {state.step === 0 && (
        <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
          <div class='d-flex align-items-end'>
            <InputComplex
              type={InputComplexType.CLIENT}
              onClick={() => setState({ step: 1 })}
              displayValue={
                (state.clientId &&
                  `${
                    selectedClient?.firstName || 'UNKNOWN_CLIENT_FIRST_NAME'
                  } ${
                    selectedClient?.lastName || 'UNKNOWN_CLIENT_LAST_NAME'
                  }`) ||
                ''
              }
              value={state.clientId || ''}
            />
            {state.clientId && (
              <a
                data-locator='go-to-client'
                href={`/clients/${state.clientId}/edit?back=${editUserBack}`}
                class='btn btn-link d-flex align-items-center justify-content-center mb-2'
              >
                <ArrowRight width='20' />
              </a>
            )}
          </div>

          <div class='mb-2'>
            <label class='form-label'>Процедури</label>
            <ul className='list-group'>
              {state.procedureId.map((pid, index) => (
                <li
                  className='list-group-item p-2'
                  key={pid}
                  data-locator={`procedure-${index}`}
                >
                  {proceduresSignal.value.find((p) => p.id === pid)?.name}
                  <input type='hidden' name='procedureId' value={pid} />
                </li>
              ))}
              <li className='list-group-item p-2'>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={() => setState({ step: 2 })}
                >
                  Обрати процедури
                </button>
              </li>
            </ul>
          </div>

          <div class='mb-2'>
            <NumberInput
              required
              inputMode='numeric'
              label='Тривалість'
              _key='duration'
              defaultValue={state.duration}
            />
          </div>

          <InputComplex
            type={InputComplexType.DATETIME}
            onClick={() => setState({ step: 3 })}
            displayValue={
              (state.day &&
                state.slot &&
                `${padStart(state.day.toString())}.${padStart(
                  (state.month + 1).toString(),
                )}.${state.year} @ ${slotToString(state.slot)}`) ||
              ''
            }
            value={{
              day: state.day,
              month: state.month,
              year: state.year,
              slot: state.slot,
            }}
          />

          <div className='container mb-2'>
            <div className='row'>
              <div class='col p-0 pe-2'>
                <label for='price2' class='form-label'>
                  Вартість
                </label>
                <input
                  required
                  type='number'
                  class='form-control'
                  id='price2'
                  name='price2'
                  value={Math.round(Object.values(state.price2).reduce(sum, 0))}
                  onChange={(e: Event) => {
                    const newPrice2 = +(e.target as HTMLInputElement).value;
                    const k = newPrice2 / calculatedPrice2;
                    const price2 = Object.fromEntries(
                      state.procedureId
                        .map(
                          (pid) =>
                            proceduresSignal.value.find((i) => i.id === pid)!,
                        )
                        .map((p) => [p.id, Math.round(p.price2 * k)]),
                    );

                    setState({ price2 });
                  }}
                />
              </div>

              <div class='col p-0 pe-2'>
                <NumberInput
                  inputMode='decimal'
                  label='На чай'
                  _key='tips'
                  defaultValue={state.tips || 0}
                />
              </div>

              <div class='col p-0'>
                <label for='price1' class='form-label'>
                  Собівартість
                </label>
                <input
                  required
                  type='number'
                  class='form-control'
                  id='price1'
                  name='price1'
                  value={Math.round(Object.values(state.price1).reduce(sum, 0))}
                  onChange={(e: Event) => {
                    const newPrice1 = +(e.target as HTMLInputElement).value;
                    const k = newPrice1 / calculatedPrice1;
                    const price1 = Object.fromEntries(
                      state.procedureId
                        .map(
                          (pid) =>
                            proceduresSignal.value.find((i) => i.id === pid)!,
                        )
                        .map((p) => [p.id, p.price1 * k]),
                    );

                    setState({ price1 });
                  }}
                />
              </div>
            </div>
          </div>

          <div class='mb-2'>
            <label for='notes' class='form-label'>
              Нотатки
            </label>
            <textarea
              class='form-control'
              id='notes'
              name='notes'
              rows={4}
              value={state.notes}
              onChange={(e) =>
                setState({ notes: (e.target as HTMLInputElement).value })
              }
            />
          </div>

          {(url.match('/edit') && (
            <div className='mb-4'>
              <div className='container'>
                <div className='row'>
                  <div className='col p-0 pe-2'>
                    <label class='form-label'>Оплата</label>
                    <div class='form-check' style={{ paddingLeft: '2rem' }}>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='paymentStatus'
                        id='paymentStatus1'
                        checked={state.paymentStatus === PaymentStatus.NOT_PAID}
                        onClick={() =>
                          setState({ paymentStatus: PaymentStatus.NOT_PAID })
                        }
                      />
                      <label class='form-check-label' for='paymentStatus1'>
                        Не сплачений
                      </label>
                    </div>
                    <div class='form-check' style={{ paddingLeft: '2rem' }}>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='paymentStatus'
                        id='paymentStatus2'
                        checked={
                          state.paymentStatus === PaymentStatus.PAID_BY_CASH
                        }
                        onClick={() =>
                          setState({
                            paymentStatus: PaymentStatus.PAID_BY_CASH,
                          })
                        }
                      />
                      <label class='form-check-label' for='paymentStatus2'>
                        Спл. кешем
                      </label>
                    </div>
                    <div class='form-check' style={{ paddingLeft: '2rem' }}>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='paymentStatus'
                        id='paymentStatus3'
                        checked={
                          state.paymentStatus === PaymentStatus.PAID_BY_CARD
                        }
                        onClick={() =>
                          setState({
                            paymentStatus: PaymentStatus.PAID_BY_CARD,
                          })
                        }
                      />
                      <label class='form-check-label' for='paymentStatus3'>
                        Спл. карткою
                      </label>
                    </div>
                  </div>
                  <div className='col p-0'>
                    <label class='form-label'>Статус</label>
                    <div class='form-check' style={{ paddingLeft: '2rem' }}>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='status'
                        id='status1'
                        checked={state.status === AppointmentStatus.PENDING}
                        onClick={() =>
                          setState({ status: AppointmentStatus.PENDING })
                        }
                      />
                      <label class='form-check-label' for='status1'>
                        Активний
                      </label>
                    </div>
                    <div class='form-check' style={{ paddingLeft: '2rem' }}>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='status'
                        id='status2'
                        checked={state.status === AppointmentStatus.CANCELLED}
                        onClick={() =>
                          setState({ status: AppointmentStatus.CANCELLED })
                        }
                      />
                      <label class='form-check-label' for='status2'>
                        Відмінений
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) ||
            null}

          <input type='hidden' name='id' value={state.id} />

          <ButtonSubmit
            requestStatus={requestStatus}
            disabled={!everythingIsInPlace}
            text={url.match('/edit') ? 'Зберегти зміни' : 'Створити запис'}
          />
        </form>
      )}

      {state.step === 1 && (
        <div class='section'>
          <ComplexFormSectionHeading
            heading='Обрати клієнта:'
            onBack={() => setState({ step: 0 })}
          />
          <ClientsFilterableList
            searchSuffix={
              <a
                class='btn btn-primary'
                href={`/clients/new?back=${newClientBack}`}
              >
                Новий&nbsp;кл.
              </a>
            }
            onSelect={(_c) => setState({ clientId: _c.id, step: 0 })}
          />
        </div>
      )}

      {state.step === 2 && (
        <ComplexFormSection
          itemActive={(_p) => state.procedureId.includes(_p.id)}
          signal={[...proceduresSignal.value].sort(proceduresSorter)}
          r={(_p) => (
            <div class='text-truncate'>
              {_p.name} <Secondary>{`(${_p.price2} грн.)`}</Secondary>
            </div>
          )}
          heading='Обрати процедури:'
          onBack={() => setState({ step: 0 })}
          onSelect={(_p) => {
            const procedureId = state.procedureId.includes(_p.id)
              ? state.procedureId.filter((pid) => pid !== _p.id)
              : [...state.procedureId, _p.id];

            const price1 = Object.fromEntries(
              procedureId
                .map((pid) => proceduresSignal.value.find((i) => i.id === pid)!)
                .map((product) => [product?.id, product?.price1 || 0]),
            );

            const price2 = Object.fromEntries(
              procedureId
                .map((pid) => proceduresSignal.value.find((i) => i.id === pid)!)
                .map((product) => [product?.id, product?.price2 || 0]),
            );

            const duration = Math.max(
              ...procedureId.map(
                (pid) =>
                  proceduresSignal.value.find((i) => i.id === pid)?.duration ||
                  0,
              ),
              0,
            );

            setState({ procedureId, price1, price2, duration });
          }}
        />
      )}

      {state.step === 3 && (
        <div class='section'>
          <ComplexFormSectionHeading
            heading='Обрати день та час:'
            onBack={() => setState({ step: 0 })}
          />
          <Calendar
            onChange={(_m, _y) => setState({ month: _m, year: _y })}
            isDayDisabled={(_d, _ds) => !_ds[_d as Day]}
            month={state.month}
            year={state.year}
            mode={Mode.REGULAR}
            activeDay={state.day}
            setActiveDay={(args) => setState({ day: args || -1 })}
            onClick={onDayClick}
            // Timeslots configuration
            filteredAppointments={[state.id || '']}
            onSlotClick={onTimeClick}
            onAppointmentClick={() => {}}
          />
        </div>
      )}
    </>
  );
};

export default AppointmentsForm;
