import { useRouter, route } from 'preact-router';
import cn from 'classnames';

import { useClient } from '@/hooks';
import { updateClient } from '@/apis';
import ArrowLeft from '@/svgs/arrow-left.svg';
import Trash from '@/svgs/trash.svg';
import { DEFAULT_ERROR } from '@/constants';

const ClientsTabs = () => {
  const [{ matches, url }] = useRouter();
  const { entity } = useClient();

  const onDelete = () => {
    if (!entity) return null;

    const p = confirm(
      `Чи справді ви хочете видалити клієнта ${entity.firstName} ${entity.lastName}?`,
    );

    if (!p) return;

    updateClient(entity.id, { deleted: true })
      .then(() => route(matches?.back || '/'))
      .catch(() => alert(DEFAULT_ERROR));
  };

  return (
    <ul class='nav nav-tabs mb-2'>
      <li class='nav-item'>
        <a
          href={matches?.back || '/'}
          class='nav-link text-primary'
          data-locator='back'
        >
          <ArrowLeft />
        </a>
      </li>
      {!entity && (
        <li class='nav-item'>
          <button type='button' class='nav-link active'>
            Новий клієнт
          </button>
        </li>
      )}
      {entity && (
        <>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/clients/${entity.id}/edit`),
              })}
              href={`/clients/${entity.id}/edit?back=${encodeURIComponent(
                matches?.back || '/',
              )}`}
            >
              Інфо
            </a>
          </li>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/clients/${entity.id}/appointments`),
              })}
              href={`/clients/${
                entity.id
              }/appointments?back=${encodeURIComponent(matches?.back || '/')}`}
            >
              Записи
            </a>
          </li>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/clients/${entity.id}/sales`),
              })}
              href={`/clients/${entity.id}/sales?back=${encodeURIComponent(
                matches?.back || '/',
              )}`}
            >
              Продажі
            </a>
          </li>
          <li class='nav-item ms-auto'>
            <button
              type='button'
              data-locator='delete-client'
              aria-label='Видалити'
              onClick={onDelete}
              class='nav-link text-danger'
            >
              <Trash />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default ClientsTabs;
