import { useRouter } from 'preact-router';
import { clientsSignal } from '@/signals';

export const useClient = () => {
  const [{ matches }] = useRouter<{ clientId: string }>();
  const entity =
    clientsSignal.value.find((c) => c.id === matches?.clientId) || null;

  return { entity };
};
