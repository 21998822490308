import { route, useRouter } from 'preact-router';
import cn from 'classnames';

import { useExpense } from '@/hooks';
import { deleteExpense } from '@/apis';
import ArrowLeft from '@/svgs/arrow-left.svg';
import Trash from '@/svgs/trash.svg';
import { DEFAULT_ERROR } from '@/constants';

const ExpensesTabs = () => {
  const [{ matches, url }] = useRouter();
  const { entity } = useExpense();

  const onDelete = () => {
    if (!entity) return null;

    const p = confirm(`Чи справді ви хочете видалити витрату ${entity.name}?`);

    if (!p) return;

    deleteExpense(entity.id)
      .then(() => route(matches?.back || '/'))
      .catch(() => alert(DEFAULT_ERROR));
  };

  return (
    <ul class='nav nav-tabs mb-2'>
      <li class='nav-item'>
        <a href={matches?.back || '/'} class='nav-link text-primary'>
          <ArrowLeft />
        </a>
      </li>
      {!entity && (
        <li class='nav-item'>
          <button type='button' class='nav-link active'>
            Нова група витрат
          </button>
        </li>
      )}
      {entity && (
        <>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/expenses/${entity.id}/edit`),
              })}
              href={`/expenses/${entity.id}/edit?back=${encodeURIComponent(
                matches?.back || '/',
              )}`}
            >
              Інфо
            </a>
          </li>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/expenses/${entity.id}/procurements`),
              })}
              href={`/expenses/${
                entity.id
              }/procurements?back=${encodeURIComponent(matches?.back || '/')}`}
            >
              Витрати
            </a>
          </li>
          <li class='nav-item ms-auto'>
            <button
              type='button'
              data-locator='delete-expense'
              aria-label='Видалити'
              onClick={onDelete}
              class='nav-link text-danger'
            >
              <Trash />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default ExpensesTabs;
