import { useEffect, useState } from 'preact/hooks';
import { useRouter } from 'preact-router';
import { getProcurement } from '@/apis';
import { Procurement } from 'cosdb-types';

export const useProcurement = () => {
  const [entity, setEntity] = useState<Procurement | null>(null);
  const [{ matches }] = useRouter<{ procurementId: string }>();

  if (!matches?.procurementId) return { entity: null };

  useEffect(() => {
    getProcurement(matches?.procurementId)
      .then((d) => setEntity(d))
      .catch(() =>
        alert('Закупівлю не знайдено в списку закупівель. Спробуйте пізніше.'),
      );
  }, []);

  return { entity };
};
