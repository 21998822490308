import { getSalesByYearAndMonth } from '@/apis';
import EmptyList from '@/components/EmptyList';
import Loader from '@/components/Loader';
import { brandNameById, productsSignal } from '@/signals';
import { groupBy, salesSorter, sumBy } from '@/utils';
import { Sale } from 'cosdb-types';
import { useEffect, useState } from 'preact/hooks';

const getTotalCost = (sales: Sale[]) => {
  return sales.reduce((acc, i) => {
    return acc + +Object.values(i.inventory)[0]?.cost;
  }, 0);
};

export const AnalyticsSales = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  const [sales, setSales] = useState<Sale[] | null>(null);

  useEffect(() => {
    setSales(null);
    getSalesByYearAndMonth({ year, month })
      .then(setSales)
      .catch(() =>
        alert(
          'Не вдалося витягнути список продажів згідно крітеріїв. Спробуйте пізніше.',
        ),
      );
  }, [month, year]);

  return (
    <>
      <h5 class='mt-4'>Продажі:</h5>
      {sales === null && <Loader />}
      {(sales !== null && sales.length) === 0 && <EmptyList />}
      {sales !== null && sales.length > 0 && (
        <table
          style={{ fontSize: '.85rem' }}
          class='table table-striped table-sm table-bordered'
        >
          <thead>
            <tr>
              <th>Назва</th>
              <th style={{ width: '15%' }}>Кільк.</th>
              <th style={{ width: '15%' }}>Варт.</th>
              <th style={{ width: '15%' }}>Соб.</th>
              <th style={{ width: '15%' }}>Дох.</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(
              groupBy([...sales].sort(salesSorter), 'productId'),
            ).map(([productId, _sales], index) => {
              const product = productsSignal.value.find(
                (prod) => prod.id === productId,
              );

              return (
                <tr>
                  <td data-locator={`sales-row-${index}-name`}>
                    <b>{brandNameById(product?.brandId)}</b>
                    {' - '}
                    {product?.name}
                  </td>
                  <td data-locator={`sales-row-${index}-count`}>
                    {_sales.length}
                  </td>
                  <td data-locator={`sales-row-${index}-price2`}>
                    {sumBy(_sales, 'price').toFixed()}
                  </td>
                  <td data-locator={`sales-row-${index}-price1`}>
                    {getTotalCost(_sales).toFixed()}
                  </td>
                  <td data-locator={`sales-row-${index}-diff`}>
                    {(sumBy(_sales, 'price') - getTotalCost(_sales)).toFixed()}
                  </td>
                </tr>
              );
            })}

            <tr>
              <td>&nbsp;</td>
              <td data-locator='sales-total-count'>{sales.length}</td>
              <td data-locator='sales-total-price2'>
                {sumBy(sales, 'price').toFixed()}
              </td>
              <td data-locator='sales-total-price1'>
                {getTotalCost(sales).toFixed()}
              </td>
              <td data-locator='sales-total-diff'>
                {(sumBy(sales, 'price') - getTotalCost(sales)).toFixed()}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};
