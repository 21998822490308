import * as db from 'firebase/firestore';
import { b, docs, where } from './_common';
import { Procurement } from 'cosdb-types';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const getProcurementsByEntityId = ({ entityId }: { entityId: string }) =>
  db
    .getDocs(
      db.query(
        db.collection(...b(), 'procurements'),
        db.or(where({ entityId }), where({ productId: entityId })),
      ),
    )
    .then(docs<Procurement>)
    .catch((e) => {
      logEvent(AnalyticsEvent.GetProcurementsByEntityIdError, {
        message: (e as { message: string }).message,
      });

      return Promise.reject(e);
    });

export const getProcurementsByYearAndMonth = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) =>
  db
    .getDocs(
      db.query(
        db.collection(...b(), 'procurements'),
        where({ year }),
        where({ month }),
      ),
    )
    .then(docs<Procurement>)
    .catch((e) => {
      logEvent(AnalyticsEvent.GetProcurementsByYearAndMonthError, {
        message: (e as { message: string }).message,
      });

      return Promise.reject(e);
    });

export const getProcurement = async (id: string) => {
  try {
    const d = await db.getDoc(db.doc(...b(), 'procurements', id));

    if (d.exists()) return d.data() as Procurement;
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.GetProcurementError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }

  return null;
};
