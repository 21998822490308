import ButtonSubmit from '@/components/ButtonSubmit';
import { RequestStatus } from '@/enums';
import { buildNewProcedure } from '@/signals';
import { Procedure } from '@/types';
import { useRouter } from 'preact-router';
import { NumberInput } from './NumberInput';

type Props = {
  onSubmit: (e: Procedure) => void;
  entity?: Procedure;
  requestStatus: RequestStatus;
};

const ProceduresForm = ({ onSubmit, entity, requestStatus }: Props) => {
  const [{ url }] = useRouter();
  const model = entity || buildNewProcedure();

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const processed = {
      id: entries.id.toString(),
      name: entries.name.toString(),
      description: entries.description.toString(),
      price1: +entries.price1,
      price2: +entries.price2,
      duration: +entries.duration,
      deleted: false,
    };

    onSubmit(processed);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div class='mb-2'>
        <label for='name' class='form-label'>
          Назва
        </label>
        <input
          type='text'
          class='form-control'
          id='name'
          name='name'
          defaultValue={model.name}
          required
        />
      </div>

      <div class='mb-2'>
        <NumberInput
          required
          label='Тривалість (в хвилинах)'
          inputMode='numeric'
          _key='duration'
          defaultValue={model.duration}
        />
      </div>

      <div class='mb-2'>
        <NumberInput
          required
          label='Вартість'
          inputMode='decimal'
          _key='price2'
          defaultValue={model.price2}
        />
      </div>

      <div class='mb-2'>
        <NumberInput
          required
          label='Собівартість'
          inputMode='decimal'
          _key='price1'
          defaultValue={model.price1}
        />
      </div>

      <div class='mb-2'>
        <label for='description' class='form-label'>
          Опис
        </label>
        <textarea
          class='form-control'
          id='description'
          name='description'
          defaultValue={model.description}
          rows={3}
        />
      </div>

      <input type='hidden' name='id' value={model.id} />

      <ButtonSubmit
        requestStatus={requestStatus}
        text={url.match('/edit') ? 'Зберегти зміни' : 'Створити процедуру'}
      />
    </form>
  );
};

export default ProceduresForm;
