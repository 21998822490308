import { brandsSignal, buildNewProduct } from '@/signals';
import ButtonSubmit from '@/components/ButtonSubmit';
import { RequestStatus } from '@/enums';
import { useRouter } from 'preact-router';
import { Product } from 'cosdb-types';
import { NumberInput } from './NumberInput';
import { Inventory } from './Inventory';

type Props = {
  onSubmit: (e: Product) => void;
  entity?: Product;
  requestStatus: RequestStatus;
  onWriteOffClick?: () => void;
};

const ProductsForm = ({
  onSubmit,
  entity,
  requestStatus,
  onWriteOffClick,
}: Props) => {
  const [{ url, matches }] = useRouter<Record<string, string>>();
  const model = entity || buildNewProduct({ brandId: matches.brand || '' });

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());

    const processed = {
      id: entries.id.toString().trim(),
      name: entries.name.toString().trim(),
      price: +entries.price,
      description: entries.description.toString(),
      deleted: false,
      brandId: entries.brandId.toString(),
      inventory: model.inventory,
    };

    onSubmit(processed);
  };

  const inStock = Object.entries(model.inventory).length > 0;

  return (
    <form onSubmit={handleSubmit}>
      <div class='mb-2'>
        <label for='brand' class='form-label'>
          Бренд
        </label>
        <select class='form-select' required name='brandId'>
          {brandsSignal.value.map((brand) => (
            <option value={brand.id} selected={brand.id === model.brandId}>
              {brand.value}
            </option>
          ))}
        </select>
      </div>
      <div class='mb-2'>
        <label for='name' class='form-label'>
          Назва
        </label>
        <input
          type='text'
          class='form-control'
          id='name'
          name='name'
          defaultValue={model.name}
          required
        />
      </div>

      <div class='mb-2'>
        <NumberInput
          required
          inputMode='numeric'
          label='Вартість'
          _key='price'
          defaultValue={model.price}
        />
      </div>

      <div class='mb-2'>
        <Inventory product={model} />
      </div>

      <div class='mb-2'>
        <label for='description' class='form-label'>
          Опис
        </label>
        <textarea
          class='form-control'
          id='description'
          name='description'
          defaultValue={model.description}
          rows={3}
        />
      </div>

      <input type='hidden' value={model.id} name='id' />

      <div className='d-flex justify-content-between'>
        <ButtonSubmit
          requestStatus={requestStatus}
          text={url.match('/edit') ? 'Зберегти зміни' : 'Створити продукт'}
        />
        {inStock && (
          <div className='btn btn-outline-secondary' onClick={onWriteOffClick}>
            Списати 1 од.
          </div>
        )}
      </div>
    </form>
  );
};

export default ProductsForm;
