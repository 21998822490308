import { useEffect, useState } from 'preact/hooks';
import { useRouter } from 'preact-router';
import { computed } from '@preact/signals';

import {
  clientsSignal,
  productsSignal,
  brandsSignal,
  buildNewSale,
  getProductById,
} from '@/signals';
import ButtonSubmit from '@/components/ButtonSubmit';
import ClientsFilterableList from '@/components/ClientsFilterableList';
import ComplexFormSectionHeading from '@/components/ComplexFormSectionHeading';
import InputComplex, { InputComplexType } from '@/components/InputComplex';
import { getOldestProcurementId, productsSorter, sumBy } from '@/utils';
import { WorkDays } from '@/types';
import { RequestStatus } from '@/enums';
import SimplifiedDatepicker from './SimplifiedDatepicker';
import { OrderStatus, Product, Sale } from 'cosdb-types';
import { getOrdersByProductId } from '@/signals/orders';
import { Inventory } from './Inventory';

type Props = {
  onSubmit: (n: Sale, o: Sale | undefined) => void;
  entity?: Sale;
  requestStatus: RequestStatus;
};

const SalesForm = ({ onSubmit, entity, requestStatus }: Props) => {
  const [{ matches, url }] = useRouter<Record<keyof Sale | 'back', string>>();

  const model = entity || buildNewSale(matches);
  const product = getProductById(model.productId);

  const isEditing = !!entity;

  const defaultState = {
    _brand: product?.brandId || null,
    ...model,
    days: {} as WorkDays,
    step: 0,
  };

  const [state, _setState] = useState(defaultState);

  type StateType = typeof defaultState;

  const setState = (args: Partial<StateType>) =>
    _setState((s) => ({ ...s, ...args }));

  const everythingIsInPlace = state.clientId && state.productId && state.day;

  const selectedClient = clientsSignal.value.find(
    (_c) => _c.id === state.clientId,
  );

  const byBrandSignal = (brand: string) =>
    computed(() => productsSignal.value.filter((_p) => _p.brandId === brand));

  const productsAvailable = (_p: Product) =>
    sumBy(Object.values(_p.inventory), 'amount');

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const processed = {
      clientId: entries.clientId.toString(),
      productId: entries.productId.toString(),
      id: entries.id.toString(),
      price: +entries.price,
      year: +entries.year,
      month: +entries.month - 1,
      day: +entries.day,
      inventory: state.inventory,
    };

    onSubmit(processed, entity);
  };

  const sameProduct = isEditing && state.productId === model.productId;

  useEffect(() => {
    const p = getProductById(state.productId);

    if (!p) return;

    const { inventory } = p;

    if (sameProduct) {
      setState({
        price: state.price,
        inventory: state.inventory,
      });
    } else {
      const id = getOldestProcurementId(inventory);
      const { cost, timestamp } = inventory[id];

      setState({
        price: p.price,
        inventory: { [id]: { amount: 1, cost, timestamp } },
      });
    }
  }, [state.productId]);

  const getPrice1 = () => {
    if (!state.productId) return '0';

    const product = getProductById(state.productId);

    if (!product) return '0';

    // we editing and product hasn't changed
    if (sameProduct) {
      return Object.values(state.inventory)[0]?.cost;
    }

    const { inventory } = product;

    const id = getOldestProcurementId(inventory);

    if (id) {
      const { cost } = inventory[id];

      return cost.toString();
    } else {
      return 'Помилка, напишіть про це розробника.';
    }
  };

  return (
    <>
      {/* Top section */}
      {state.step === 0 && (
        <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
          <InputComplex
            type={InputComplexType.CLIENT}
            onClick={() => setState({ step: 1 })}
            displayValue={
              (state.clientId &&
                `${selectedClient?.firstName || 'UNKNOWN_CLIENT_FIRST_NAME'} ${
                  selectedClient?.lastName || 'UNKNOWN_CLIENT_LAST_NAME'
                }`) ||
              ''
            }
            value={state.clientId || ''}
          />

          <div className='mb-2'>
            <label class='form-label' htmlFor='_brand'>
              Бренд
            </label>
            <select
              name='_brand'
              id='_brand'
              className='form-select'
              onChange={(e: Event) => {
                setState({
                  _brand: (e.target as HTMLInputElement).value,
                  productId: '',
                });
              }}
            >
              <option value='' selected={!state._brand}>
                Обрати бренд
              </option>
              {brandsSignal.value.map((brand) => (
                <option value={brand.id} selected={brand.id === state._brand}>
                  {brand.value}
                </option>
              ))}
            </select>
          </div>

          <div className='mb-2'>
            <label class='form-label' htmlFor='productId'>
              Продукт
            </label>
            <select
              name='productId'
              id='productId'
              className='form-select'
              disabled={!state._brand}
              onChange={(e: Event) => {
                setState({
                  productId: (e.target as HTMLInputElement).value,
                });
              }}
            >
              <option value='' selected={!state.productId}>
                Обрати продукт
              </option>
              {(state._brand ? [...byBrandSignal(state._brand).value] : [])
                .sort(productsSorter)
                .map((product) => {
                  const openOrders = getOrdersByProductId(
                    product.id,
                    OrderStatus.OPEN,
                  );
                  const isAvailable = productsAvailable(product);

                  const prefix =
                    (!isAvailable && '(в наявн.: 0) ') ||
                    (openOrders.length &&
                      `(є замов.: ${openOrders.length}) `) ||
                    '';

                  return (
                    <option
                      disabled={
                        !(
                          isAvailable ||
                          (isEditing && model.productId === product.id)
                        )
                      }
                      value={product.id}
                      selected={product.id === state.productId}
                    >
                      {`${prefix}${product.name}`}
                    </option>
                  );
                })}
            </select>

            {state.productId && (
              <div class='mt-2'>
                <Inventory product={getProductById(state.productId)!} />
              </div>
            )}
          </div>

          <div className='mb-2'>
            <SimplifiedDatepicker
              required
              day={state.day}
              month={state.month + 1}
              year={state.year}
            />
          </div>

          <input type='hidden' name='amount' value={1} />

          <div className='container mb-3'>
            <div className='row'>
              <div class='col p-0 pe-2'>
                <label for='price' class='form-label'>
                  Вартість
                </label>
                <input
                  disabled={!state.productId}
                  value={state.price}
                  class='form-control'
                  required
                  type='number'
                  name='price'
                  id='price'
                  min={0}
                  step={1}
                  onChange={(e) =>
                    setState({
                      price: +(e.target as HTMLInputElement).value,
                    })
                  }
                />
              </div>
              <div className='col p-0'>
                <label class='form-label' for='name'>
                  Собівартість (авт.):
                </label>
                <input
                  disabled={!state.productId}
                  value={getPrice1()}
                  class='form-control'
                  type='text'
                  readOnly
                  name='price1'
                  id='price1'
                />
              </div>
            </div>
          </div>

          <input type='hidden' name='id' value={model.id} />

          <ButtonSubmit
            requestStatus={requestStatus}
            disabled={!everythingIsInPlace}
            text={url.match('/edit') ? 'Зберегти зміни' : 'Створити продаж'}
          />
        </form>
      )}

      {state.step === 1 && (
        <div class='section'>
          <ComplexFormSectionHeading
            heading='Обрати клієнта:'
            onBack={() => setState({ step: 0 })}
          />
          <ClientsFilterableList
            onSelect={(_c) => setState({ clientId: _c.id, step: 0 })}
          />
        </div>
      )}
    </>
  );
};

export default SalesForm;
