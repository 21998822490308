import * as db from 'firebase/firestore';
import { b } from './_common';
import { Procedure } from '@/types';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const createProcedure = (props: Procedure) =>
  db.setDoc(db.doc(...b(), 'procedures', props.id), props).catch((e) => {
    logEvent(AnalyticsEvent.CreateProcedureError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  });

export const updateProcedure = (id: string, props: Partial<Procedure>) =>
  db.updateDoc(db.doc(...b(), 'procedures', id), props).catch((e) => {
    logEvent(AnalyticsEvent.UpdateProcedureError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  });
