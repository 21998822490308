import { useState } from 'preact/hooks';
import { memo, ReactElement } from 'preact/compat';

import { activeClientsSignal } from '@/signals';
import ChevronRight from '@/svgs/chevron-right.svg';
import { daysBetween, onlyLettersAndDigits } from '@/utils';
import { Client } from '@/types';

type Props = {
  onSelect: (a: Client) => void;
  showChevron?: boolean;
  searchSuffix?: ReactElement;
};

type ClientsFiterableListItemProps = {
  client: Client;
  onSelect: (client: Client) => void;
  showChevron: boolean;
};

const ClientsFiterableListItem = memo(
  ({ client, onSelect, showChevron }: ClientsFiterableListItemProps) => {
    return (
      <li
        data-search-data={
          onlyLettersAndDigits(client.firstName) +
          onlyLettersAndDigits(client.lastName) +
          onlyLettersAndDigits(client.phoneNumber)
        }
        onClick={() => onSelect(client)}
        key={client.id}
        class='list-group-item p-2 d-flex justify-content-between align-items-center'
      >
        <div>
          <div class='fw-bold'>
            {`${client.lastName} ${client.firstName}`}
            {(client.birthDate &&
              daysBetween(
                client.birthDate?.toDate().getUTCDate(),
                client.birthDate?.toDate().getUTCMonth() + 1,
              ) > 0 &&
              daysBetween(
                client.birthDate?.toDate().getUTCDate(),
                client.birthDate?.toDate().getUTCMonth() + 1,
              ) < 7 && (
                <span class='badge text-bg-success ms-2'>Скоро ДР</span>
              )) ||
              null}
          </div>
          <div>{client.phoneNumber}</div>
        </div>
        {showChevron && <ChevronRight />}
      </li>
    );
  },
);

const ClientsFilterableList = ({
  onSelect,
  showChevron = false,
  searchSuffix,
}: Props) => {
  const [search, setSearch] = useState('');
  const s = onlyLettersAndDigits(search);

  return (
    <>
      {activeClientsSignal.value.length === 0 && (
        <div className='d-flex'>
          <button
            type='button'
            class='btn btn-outline-secondary fst-italic'
            disabled
            style={{ flex: 1 }}
          >
            Нічо нема...
          </button>
          {searchSuffix && <div className='ms-2'>{searchSuffix}</div>}
        </div>
      )}

      {activeClientsSignal.value.length > 0 && (
        <>
          {s && (
            <style>{`[data-search-data]:not([data-search-data*="${s}"]) { display: none !important; }`}</style>
          )}
          <div className='mb-2 d-flex'>
            <div className='input-group'>
              <input
                type='text'
                class='form-control'
                name='search'
                placeholder='Пошук'
                value={search}
                onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
              />
              <button
                class='btn btn-outline-secondary'
                type='button'
                onClick={() => setSearch('')}
              >
                &times;
              </button>
            </div>
            {searchSuffix && <div className='ms-2'>{searchSuffix}</div>}
          </div>
          <ul class='list-group'>
            {activeClientsSignal.value.map((c) => (
              <ClientsFiterableListItem
                client={c}
                showChevron={showChevron}
                onSelect={onSelect}
              />
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default ClientsFilterableList;
