import ArrowLeft from '@/svgs/arrow-left.svg';
import ArrowRight from '@/svgs/arrow-right.svg';

const MONTH_NAMES: Record<number, string> = {
  0: 'Січень',
  1: 'Лютий',
  2: 'Березень',
  3: 'Квітень',
  4: 'Травень',
  5: 'Червень',
  6: 'Липень',
  7: 'Серпень',
  8: 'Вересень',
  9: 'Жовтень',
  10: 'Листопад',
  11: 'Грудень',
};

type Props = {
  month: number;
  year: number;
  onChange: (a: number, b: number) => void;
};

const CalendarHeader = ({ month, year, onChange }: Props) => {
  const onPrev = () => {
    if (month === 0) {
      onChange(11, year - 1);
    } else {
      onChange(month - 1, year);
    }
  };

  const onNext = () => {
    if (month === 11) {
      onChange(0, year + 1);
    } else {
      onChange(month + 1, year);
    }
  };

  return (
    <div class='mb-2 d-flex justify-content-between align-items-center'>
      <button
        type='button'
        class='btn btn-sm btn-secondary'
        onClick={onPrev}
        data-locator='calendar-prev'
      >
        <ArrowLeft />
      </button>
      <b data-locator='calendar-head'>{`${MONTH_NAMES[month]} - ${year}`}</b>
      <button
        type='button'
        class='btn btn-sm btn-secondary'
        onClick={onNext}
        data-locator='calendar-next'
      >
        <ArrowRight />
      </button>
    </div>
  );
};

export default CalendarHeader;
