import cn from 'classnames';

import ComplexFormSectionHeading from '@/components/ComplexFormSectionHeading';

type Props<T> = {
  signal: T[];
  onBack: () => void;
  onSelect: (a: T) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  r: (a: T) => any;
  heading: string;
  itemDisabled?: (a: T) => boolean;
  itemActive?: (a: T) => boolean;
};

const ComplexFormSection = <T extends { deleted: boolean; id: string }>({
  signal,
  onBack,
  onSelect,
  r,
  heading,
  itemDisabled = () => false,
  itemActive = () => false,
}: Props<T>) => {
  return (
    <div class='section'>
      <ComplexFormSectionHeading heading={heading} onBack={onBack} />
      <ul class='list-group'>
        {signal
          .filter((_c) => !_c.deleted)
          .map((_c) => {
            return (
              <li
                class={cn('list-group-item list-group-item-action p-2', {
                  'bg-success bg-opacity-25': itemActive(_c),
                  'bg-danger bg-opacity-25 pe-none': itemDisabled(_c),
                })}
                onClick={() => onSelect(_c)}
                key={_c.id}
              >
                {r(_c)}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ComplexFormSection;
