import { useEffect, useState } from 'preact/hooks';
import { useRouter } from 'preact-router';

import { getAppointment } from '@/apis';
import { Appointment } from '@/types';

export const useAppointment = () => {
  const [entity, setEntity] = useState<Appointment | null>(null);
  const [{ matches }] = useRouter<{ appointmentId: string }>();

  if (!matches?.appointmentId) return { entity: null };

  useEffect(() => {
    getAppointment(matches?.appointmentId)
      .then((d) => setEntity(d))
      .catch(() =>
        alert('Запис не знайдено в списку записів. Спробуйте пізніше.'),
      );
  }, []);

  return { entity };
};
