import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';

import ExpensesForm from '@/components/ExpensesForm';
import ExpensesTabs from '@/components/ExpensesTabs';
import { useExpense } from '@/hooks';
import { updateExpense } from '@/apis';
import { RequestStatus } from '@/enums';
import Loader from '@/components/Loader';
import { Expense } from '@/types';
import { DEFAULT_ERROR } from '@/constants';

const ExpensesEdit = () => {
  const [{ matches }] = useRouter();
  const { entity } = useExpense();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Expense) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    updateExpense(properties.id, properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  if (!entity) return <Loader />;

  return (
    <>
      <ExpensesTabs />
      <ExpensesForm
        onSubmit={onSubmit}
        entity={entity}
        requestStatus={requestStatus}
      />
    </>
  );
};

export default ExpensesEdit;
