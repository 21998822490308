import { Inventory, Sale } from 'cosdb-types';
import { getProductById } from '@/signals';
import * as db from 'firebase/firestore';
import { b, isInventoryValid } from './_common';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';

export const deleteSaleAndUpdateProduct = ({ sale }: { sale: Sale }) => {
  const product = getProductById(sale.productId);

  if (!product) {
    return Promise.reject(new Error('PRODUCT_NOT_FOUND'));
  }

  const [id, { cost, timestamp }] = Object.entries(sale.inventory)[0];
  const { inventory } = product;
  const newInventory: Inventory = {
    ...inventory,
    [id]: inventory[id]
      ? { ...inventory[id], amount: inventory[id].amount + 1 }
      : { amount: 1, cost, timestamp },
  };

  if (!isInventoryValid(newInventory)) {
    return Promise.reject(new Error('INVENTORY_INVALID'));
  }

  try {
    return db
      .writeBatch(firestore)
      .delete(db.doc(...b(), 'sales', sale.id))
      .update(db.doc(...b(), 'products', product.id), {
        inventory: newInventory,
      })
      .commit();
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.DeleteSaleAndUpdateProductError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
};
