import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';

import { createExpense } from '@/apis';
import ExpensesForm from '@/components/ExpensesForm';
import ExpensesTabs from '@/components/ExpensesTabs';
import { RequestStatus } from '@/enums';
import { Expense } from '@/types';
import { DEFAULT_ERROR } from '@/constants';

const ExpensesNew = () => {
  const [{ matches }] = useRouter();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Expense) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    createExpense(properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  return (
    <>
      <ExpensesTabs />
      <ExpensesForm onSubmit={onSubmit} requestStatus={requestStatus} />
    </>
  );
};

export default ExpensesNew;
