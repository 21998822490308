import * as db from 'firebase/firestore';
import { b, isInventoryValid } from './_common';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';
import { Inventory, Procurement, ProcurementType } from 'cosdb-types';
import { getProductById } from '@/signals';

export const createProcurementAndUpdateProduct = async ({
  procurement,
}: {
  procurement: Procurement;
}) => {
  if (procurement.type !== ProcurementType.PRODUCT_FOR_SALE) {
    return db.setDoc(
      db.doc(...b(), 'procurements', procurement.id),
      procurement,
    );
  }

  const product = getProductById(procurement.entityId);

  if (!product) return Promise.reject(new Error('PRODUCT_NOT_FOUND'));

  const { inventory = {} } = product;
  const newInventory: Inventory = {
    ...inventory,
    [procurement.id]: {
      cost: procurement.price,
      amount: procurement.amount,
      timestamp: Date.UTC(procurement.year, procurement.month, procurement.day),
    },
  };

  if (!isInventoryValid(newInventory)) {
    return Promise.reject(new Error('INVENTORY_INVALID'));
  }

  try {
    return db
      .writeBatch(firestore)
      .set(db.doc(...b(), 'procurements', procurement.id), procurement)
      .update(db.doc(...b(), 'products', product.id), {
        inventory: newInventory,
      })
      .commit();
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.CreateProcurementAndUpdateProductError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
};
