import { updateCompany } from '@/apis/company';
import { updateFcmTokenIfNeeded } from '@/apis/updateFcmTokenIfNeeded';
import { NOTIFICATIONS_SUBSCRIPTION_ERROR } from '@/constants';
import { auth } from '@/firebase';
import { companySignal } from '@/signals/company';
import { Settings } from 'cosdb-types';
import { route } from 'preact-router';

const SettingsIndex = () => {
  if (companySignal.value === undefined) return null;

  const {
    productsEnabled,
    analyticsEnabled,
    expensesEnabled,
    startTime,
    birthdayPush,
  } = companySignal.value?.settings || {};

  const onChangeSettings = (k: keyof Settings, v: boolean | number) =>
    updateCompany({ settings: { ...companySignal.value?.settings, [k]: v } });

  return (
    <>
      <h5 class='my-3'>Налаштування:</h5>
      <div class='form-check'>
        <input
          class='form-check-input'
          type='radio'
          name='startTime'
          id='startTime8'
          checked={startTime === 480}
          onChange={() => onChangeSettings('startTime', 480)}
        />
        <label class='form-check-label' for='startTime8'>
          Починати день з 08:00
        </label>
      </div>
      <div class='form-check'>
        <input
          class='form-check-input'
          type='radio'
          name='startTime'
          id='startTime9'
          checked={startTime === 540 || !startTime}
          onChange={() => onChangeSettings('startTime', 540)}
        />
        <label class='form-check-label' for='startTime9'>
          Починати день з 09:00
        </label>
      </div>
      <hr />
      <div class='form-check'>
        <input
          class='form-check-input'
          type='checkbox'
          value=''
          id='show-products-section'
          checked={productsEnabled}
          onChange={() => onChangeSettings('productsEnabled', !productsEnabled)}
        />
        <label class='form-check-label' for='show-products-section'>
          Показати секцію з продуктами
        </label>
      </div>
      <div class='form-check'>
        <input
          class='form-check-input'
          type='checkbox'
          value=''
          id='show-expenses-section'
          checked={expensesEnabled}
          onChange={() => onChangeSettings('expensesEnabled', !expensesEnabled)}
        />
        <label class='form-check-label' for='show-expenses-section'>
          Показати секцію з витратами
        </label>
      </div>
      <div class='form-check'>
        <input
          class='form-check-input'
          type='checkbox'
          value=''
          id='show-analytics-section'
          checked={analyticsEnabled}
          onChange={() =>
            onChangeSettings('analyticsEnabled', !analyticsEnabled)
          }
        />
        <label class='form-check-label' for='show-analytics-section'>
          Показати секцію з аналітикою
        </label>
      </div>
      <div class='form-check'>
        <input
          class='form-check-input'
          type='checkbox'
          value=''
          id='b-day-push'
          checked={birthdayPush}
          onClick={async (e) => {
            const newValue = !birthdayPush;

            if (newValue === true) {
              e.preventDefault();
              Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                  updateFcmTokenIfNeeded().then(() =>
                    onChangeSettings('birthdayPush', true),
                  );
                } else {
                  alert(NOTIFICATIONS_SUBSCRIPTION_ERROR);
                }
              });
            } else {
              onChangeSettings('birthdayPush', false);
            }
          }}
        />
        <label class='form-check-label' for='b-day-push'>
          Отримувати сповіщення
          <br />
          про дні народження
        </label>
      </div>
      <hr />
      <button
        className='btn btn-primary'
        onClick={() => auth.signOut().then(() => route('/'))}
      >
        Разлогінитися
      </button>
    </>
  );
};

export default SettingsIndex;
