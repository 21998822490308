import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';
import ProductsForm from '@/components/ProductsForm';
import ProductsTabs from '@/components/ProductsTabs';
import { useProduct } from '@/hooks';
import { updateProduct } from '@/apis';
import { RequestStatus } from '@/enums';
import Loader from '@/components/Loader';
import { buildNewSale } from '@/signals';
import { DEFAULT_ERROR } from '@/constants';
import { Product, Sale } from 'cosdb-types';
import { createSaleAndUpdateProduct } from '@/apis/createSaleAndUpdateProduct';
import { getOldestProcurementId } from '@/utils';

const ProductsEdit = () => {
  const [{ matches }] = useRouter();
  const { entity } = useProduct();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Product) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    updateProduct(properties.id, properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  const onWriteOffClick = async () => {
    if (!entity) return;

    const p = confirm('Чи справді ви хочете списати 1 од. цього товару?');

    if (!p) return;

    const { inventory } = entity;

    if (!Object.keys(inventory).length) return alert(DEFAULT_ERROR);

    const id = getOldestProcurementId(inventory);
    const { cost, timestamp } = inventory[id];
    const sale: Sale = {
      ...buildNewSale({}),
      price: 0,
      inventory: { [id]: { amount: 1, cost, timestamp } },
      productId: entity.id,
      clientId: 'NONE',
    };

    createSaleAndUpdateProduct({ sale })
      .then(() => alert('1 од. товару списано.'))
      .catch(() => alert('Сталася помилка, товар не списано.'));
  };

  if (!entity) return <Loader />;

  return (
    <>
      <ProductsTabs />
      <ProductsForm
        onSubmit={onSubmit}
        entity={entity}
        requestStatus={requestStatus}
        onWriteOffClick={onWriteOffClick}
      />
    </>
  );
};

export default ProductsEdit;
