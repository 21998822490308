import { useRouter } from 'preact-router';
import { expensesSignal } from '@/signals';

export const useExpense = () => {
  const [{ matches }] = useRouter<{ expenseId: string; entityId: string }>();
  const entity =
    expensesSignal.value.find(
      (p) => p.id === matches?.expenseId || p.id === matches?.entityId,
    ) || null;

  return { entity };
};
