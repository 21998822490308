import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import Loader from '@/components/Loader';
import EmptyList from '@/components/EmptyList';
import ChevronRight from '@/svgs/chevron-right.svg';
import { salesSignal, productsSignal, brandNameById } from '@/signals';
import { getSalesByProductId } from '@/apis';
import { useProduct } from '@/hooks';
import { padStart, salesSorter, sumBy } from '@/utils';
import ProductsTabs from '@/components/ProductsTabs';
import classNames from 'classnames';
import { Sale } from 'cosdb-types';

const ProductsSales = () => {
  const { entity } = useProduct();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (entity) {
      getSalesByProductId({ productId: entity.id })
        .then((sales) => {
          salesSignal.value = sales;
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          alert('Не вдалося дістати інформацію про продажі.');
        });
    }

    return () => {
      salesSignal.value = [];
    };
  }, []);

  const productBySale = (s: Sale) =>
    productsSignal.value.find((p) => p.id === s.productId);

  const getValue = (a: Sale) => {
    const product = productBySale(a);

    return [
      padStart(a.day.toString()),
      '.',
      padStart((a.month + 1).toString()),
      '.',
      a.year,
      ' / ',
      brandNameById(product?.brandId) || 'NON_EXISTING_PRODUCT_BRAND',
      ' / ',
      product?.name || 'NON_EXISTING_PRODUCT_NAME',
      ' / ',
      `${a.price} грн`,
    ].join('');
  };

  return (
    <>
      <ProductsTabs />

      {loading && <Loader />}

      {!loading && entity && (
        <>
          <a
            class={classNames('btn btn-sm btn-primary mb-2', {
              disabled: sumBy(Object.values(entity.inventory), 'amount') === 0,
            })}
            href={`/sales/new?productId=${entity.id}&back=${encodeURIComponent(
              `/products/${entity.id}/sales`,
            )}`}
          >
            Новий продаж
          </a>

          {salesSignal.value.length > 0 && (
            <ul class='list-group'>
              {[...salesSignal.value].sort(salesSorter).map((a) => {
                return (
                  <li
                    key={a.id}
                    onClick={() =>
                      route(
                        `/sales/${
                          a.id
                        }/edit?back=${`/products/${entity.id}/sales`}`,
                      )
                    }
                    class='list-group-item p-2 d-flex justify-content-between align-items-center'
                  >
                    <div class='text-truncate'>{getValue(a)}</div>
                    {a.clientId === 'NONE' && (
                      <span class='badge rounded-pill bg-danger ms-2'>
                        Списано
                      </span>
                    )}
                    <ChevronRight style={{ flexShrink: 0 }} class='ms-2' />
                  </li>
                );
              })}
            </ul>
          )}

          {salesSignal.value.length === 0 && <EmptyList />}
        </>
      )}
    </>
  );
};

export default ProductsSales;
