import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getMessaging, MessagePayload, onMessage } from 'firebase/messaging';
import { route } from 'preact-router';

const firebaseConfig = {
  apiKey: 'AIzaSyBUGMjWXDKs5FJXy0UeENLQyBumVQjkMr0',
  authDomain: 'cosdb2-c2d7c.firebaseapp.com',
  projectId: 'cosdb2-c2d7c',
  storageBucket: 'cosdb2-c2d7c.appspot.com',
  messagingSenderId: '329506444047',
  appId: '1:329506444047:web:aad84c5525fd93e94de47a',
  measurementId: 'G-LQS7B7LYF1',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

if (process.env.PREACT_APP_EMULATORS === 'dev') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  connectFirestoreEmulator(firestore, '127.0.0.1', 8085);
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

export enum AnalyticsEvent {
  AppLoaded = 'AppLoaded',
  AppOperational = 'AppOperational',

  // errors
  SubscribeToCompanyError = 'SubscribeToCompanyError',
  SubscribeToBrandsError = 'SubscribeToBrandsError',
  SubscribeToClientsError = 'SubscribeToClientsError',
  SubscribeToExpensesError = 'SubscribeToExpensesError',
  SubscribeToOrdersError = 'SubscribeToOrdersError',
  SubscribeToProdceduresError = 'SubscribeToProdceduresError',
  SubscribeToProductsError = 'SubscribeToProductsError',
  GetAppointmentsByClientIdError = 'GetAppointmentsByClientIdError',
  GetAppointmentsByYearAndMonthError = 'GetAppointmentsByYearAndMonthError',
  GetAppointmentError = 'GetAppointmentError',
  CreateAppointmentError = 'CreateAppointmentError',
  UpdateAppointmentError = 'UpdateAppointmentError',
  DeleteAppointmentError = 'DeleteAppointmentError',
  CreateClientError = 'CreateClientError',
  UpdateClientError = 'UpdateClientError',
  GetCompanyError = 'GetCompanyError',
  UpdateCompanyError = 'UpdateCompanyError',
  CreateProcurementAndUpdateProductError = 'CreateProcurementAndUpdateProductError',
  DeleteProcurementAndUpdateProductError = 'DeleteProcurementAndUpdateProductError',
  DeleteSaleAndUpdateProductError = 'DeleteSaleAndUpdateProductError',
  CreateExpenseError = 'CreateExpenseError',
  UpdateExpenseError = 'UpdateExpenseError',
  DeleteExpenseError = 'DeleteExpenseError',
  CreateProcedureError = 'CreateProcedureError',
  UpdateProcedureError = 'UpdateProcedureError',
  GetProcurementsByEntityIdError = 'GetProcurementsByEntityIdError',
  GetProcurementsByYearAndMonthError = 'GetProcurementsByYearAndMonthError',
  GetProcurementError = 'GetProcurementError',
  CreateProductError = 'CreateProductError',
  UpdateProductError = 'UpdateProductError',
  GetSalesByProductIdError = 'GetSalesByProductIdError',
  GetSalesByClientIdError = 'GetSalesByClientIdError',
  GetSalesByYearAndMonthError = 'GetSalesByYearAndMonthError',
  GetSaleError = 'GetSaleError',
  UpdateFcmTokenIfNeededError = 'UpdateFcmTokenIfNeededError',
  UpdateProcurementAndUpdateProductsError = 'UpdateProcurementAndUpdateProductsError',
  UpdateSaleAndUpdateProductsError = 'UpdateSaleAndUpdateProductsError',
  GetWorkDaysError = 'GetWorkDaysError',
  CreateOrUpdateWorkDaysError = 'CreateOrUpdateWorkDaysError',
  CreateSaleAndUpdateProductError = 'CreateSaleAndUpdateProductError',
}

const pascalToUnderscore = (str: string): string => {
  return str
    .replace(/([A-Z])/g, '_$1')
    .toLowerCase()
    .replace(/^_/, '');
};

export const logEvent = (
  name: AnalyticsEvent,
  payload: Record<string, string | number>,
) => firebaseLogEvent(analytics, pascalToUnderscore(name), payload);

export const vapidKey =
  'BI3ULrvDBLcjGlkTLGxDSet-Qq1-P5AMaB9ix5TlrQQcNGE_yyXrdHytvNBNYPV1slzndXd_wz8o0ff4ojuLfjs';

onMessage(messaging, (payload: MessagePayload) => {
  if (payload.data?.messageType === 'BDAY') {
    const n = new Notification('День народження', {
      body: `Сьогодні день народження у ${payload.data.firstName.trim()} ${payload.data.lastName.trim()}!`,
    });

    n.onclick = () => route(`/clients/${payload.data?.id}`);
  }
});
