import * as signals from '@/signals';
import * as db from 'firebase/firestore';
import { firestore } from '@/firebase';
import { Inventory } from 'cosdb-types';

export const b = (): [db.Firestore, string, string] =>
  signals.userSignal.value
    ? [firestore, 'c', signals.userSignal.value.uid]
    : [firestore, 'c', ''];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const docs = <T,>(snap: db.QuerySnapshot, converter?: (t: any) => T) =>
  snap.docs.map((d) => {
    const data = { ...d.data(), id: d.id };

    return (converter?.(data) || data) as T;
  });

export const where = (prop: Record<string, string | number | boolean>) => {
  const [[k, v]] = Object.entries(prop);

  return db.where(k, '==', v);
};

export const isInventoryValid = (inventory: Inventory) => {
  if (
    Object.values(inventory).some(
      (i) => typeof i.amount === 'number' && i.amount < 1,
    )
  ) {
    return false;
  } else if (
    Object.values(inventory).some((i) => typeof i.amount !== 'number')
  ) {
    return false;
  }

  return true;
};
