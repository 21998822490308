import { Inventory, Procurement, ProcurementType } from 'cosdb-types';
import * as db from 'firebase/firestore';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';
import { b, isInventoryValid } from './_common';
import { getProductById } from '@/signals';

export const updateProcurementAndUpdateProducts = ({
  oldProcurement,
  newProcurement,
}: {
  oldProcurement: Procurement;
  newProcurement: Procurement;
}) => {
  try {
    const id = newProcurement.id;
    const oldProduct = getProductById(oldProcurement.entityId);
    const newProduct = getProductById(newProcurement.entityId);

    if (
      oldProduct &&
      oldProduct.inventory[id]?.amount !== oldProcurement.amount &&
      oldProcurement.type === ProcurementType.PRODUCT_FOR_SALE
    ) {
      return Promise.reject(new Error('PROCUREMENT_DATA_DIRTY'));
    }

    const batch = db.writeBatch(firestore);

    batch.update(
      db.doc(...b(), 'procurements', newProcurement.id),
      newProcurement,
    );

    if (
      oldProduct &&
      oldProcurement.type === ProcurementType.PRODUCT_FOR_SALE
    ) {
      const inventory: Inventory = Object.fromEntries(
        Object.entries(oldProduct.inventory).filter(
          ([k]) => k !== oldProcurement.id,
        ),
      );

      if (!isInventoryValid(inventory)) {
        return Promise.reject(new Error('INVENTORY_INVALID'));
      }

      batch.update(db.doc(...b(), 'products', oldProduct.id), { inventory });
    }

    if (
      newProduct &&
      newProcurement.type === ProcurementType.PRODUCT_FOR_SALE
    ) {
      const { year, month, day } = newProcurement;
      const inventory: Inventory = {
        ...newProduct.inventory,
        [newProcurement.id]: {
          amount: newProcurement.amount,
          cost: newProcurement.price,
          timestamp: Date.UTC(year, month, day),
        },
      };

      if (!isInventoryValid(inventory)) {
        return Promise.reject(new Error('INVENTORY_INVALID'));
      }

      batch.update(db.doc(...b(), 'products', newProduct.id), { inventory });
    }

    return batch.commit();
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.UpdateProcurementAndUpdateProductsError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
};
