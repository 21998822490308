import { route, useRouter } from 'preact-router';

import { useProcedure } from '@/hooks';
import { updateProcedure } from '@/apis';
import ArrowLeft from '@/svgs/arrow-left.svg';
import Trash from '@/svgs/trash.svg';
import { DEFAULT_ERROR } from '@/constants';

const ProceduresTabs = () => {
  const [{ matches }] = useRouter();
  const { entity } = useProcedure();

  const onDelete = () => {
    if (!entity) return null;

    const p = confirm(
      `Чи справді ви хочете видалити процедуру ${entity.name}?`,
    );

    if (!p) return;

    updateProcedure(entity.id, { deleted: true })
      .then(() => route(matches?.back || '/'))
      .catch(() => alert(DEFAULT_ERROR));
  };

  return (
    <ul class='nav nav-tabs mb-2'>
      <li class='nav-item'>
        <a href={matches?.back || '/'} class='nav-link text-primary'>
          <ArrowLeft />
        </a>
      </li>
      {!entity && (
        <li class='nav-item'>
          <button type='button' class='nav-link active'>
            Нова процедура
          </button>
        </li>
      )}
      {entity && (
        <>
          <li class='nav-item'>
            <span class='nav-link active'>Інфо</span>
          </li>
          <li class='nav-item ms-auto'>
            <button
              type='button'
              data-locator='delete-procedure'
              aria-label='Видалити'
              onClick={onDelete}
              class='nav-link text-danger'
            >
              <Trash />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default ProceduresTabs;
