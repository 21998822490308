import * as db from 'firebase/firestore';
import { Expense } from '@/types';
import { b } from './_common';
import { AnalyticsEvent, logEvent } from '@/firebase';

export const createExpense = (props: Expense) =>
  db.setDoc(db.doc(...b(), 'expenses', props.id), props).catch((e) => {
    logEvent(AnalyticsEvent.CreateExpenseError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  });

export const updateExpense = (id: string, props: Partial<Expense>) =>
  db.updateDoc(db.doc(...b(), 'expenses', id), props).catch((e) => {
    logEvent(AnalyticsEvent.UpdateExpenseError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  });

export const deleteExpense = (id: string) =>
  db.deleteDoc(db.doc(...b(), 'expenses', id)).catch((e) => {
    logEvent(AnalyticsEvent.DeleteExpenseError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  });
