import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';
import { useSale } from '@/hooks';
import SalesForm from '@/components/SalesForm';
import SalesTabs from '@/components/SalesTabs';
import Loader from '@/components/Loader';
import { RequestStatus } from '@/enums';
import { DEFAULT_ERROR } from '@/constants';
import { Sale } from 'cosdb-types';
import { updateSaleAndUpdateProducts } from '@/apis/updateSaleAndUpdateProducts';

const SalesEdit = () => {
  const [{ matches }] = useRouter();
  const { entity } = useSale();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Sale, old: Sale | undefined) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    updateSaleAndUpdateProducts({ newSale: properties, oldSale: old! })
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  if (!entity) return <Loader />;

  return (
    <>
      <SalesTabs entity={entity} />
      <SalesForm
        onSubmit={onSubmit}
        entity={entity}
        requestStatus={requestStatus}
      />
    </>
  );
};

export default SalesEdit;
