import { buildNewExpense } from '@/signals';
import ButtonSubmit from '@/components/ButtonSubmit';
import { Expense } from '@/types';
import { RequestStatus } from '@/enums';
import { useRouter } from 'preact-router';

type Props = {
  onSubmit: (e: Expense) => void;
  entity?: Expense;
  requestStatus: RequestStatus;
};

const ExpensesForm = ({ onSubmit, entity, requestStatus }: Props) => {
  const [{ url }] = useRouter();
  const model = entity || buildNewExpense();

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const processed = {
      id: entries.id.toString().trim(),
      name: entries.name.toString().trim(),
      notes: entries.notes.toString(),
    };

    onSubmit(processed);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div class='mb-2'>
        <label for='name' class='form-label'>
          Назва
        </label>
        <input
          type='text'
          class='form-control'
          id='name'
          name='name'
          defaultValue={model.name}
          required
        />
      </div>

      <div class='mb-2'>
        <label for='notes' class='form-label'>
          Нотатки
        </label>
        <textarea
          class='form-control'
          id='notes'
          name='notes'
          defaultValue={model.notes}
          rows={3}
        />
      </div>

      <input type='hidden' value={model.id} name='id' />

      <ButtonSubmit
        requestStatus={requestStatus}
        text={url.match('/edit') ? 'Зберегти зміни' : 'Створити групу'}
      />
    </form>
  );
};

export default ExpensesForm;
