import { route, useRouter } from 'preact-router';
import cn from 'classnames';

import ChevronRight from '@/svgs/chevron-right.svg';
import { brandsSignal, productsSignal } from '@/signals';
import { productsSorter, sumBy } from '@/utils';
import EmptyList from '@/components/EmptyList';
import Secondary from '@/components/Secondary';

type PossibleSearchParams = 'brand';

const ProductsIndex = () => {
  const [{ matches }] =
    useRouter<Record<PossibleSearchParams, string | undefined>>();
  const { brand } = matches;
  const brandName = brandsSignal.value.find((b) => b.id === brand)?.value;
  const activeProducts = productsSignal.value.filter(
    (c) => !c.deleted && c.brandId === brand,
  );

  return (
    <>
      <div class='mb-2'>
        <a
          class='btn btn-sm btn-primary'
          href={`/products/new?brand=${brand || ''}&back=${encodeURIComponent(
            `/products?brand=${brand || ''}`,
          )}`}
        >
          Новий продукт
        </a>
      </div>

      {activeProducts.length > 0 && (
        <>
          <h5 class='my-3'>Продукти ({brandName}):</h5>
          <ul class='list-group'>
            {[...activeProducts].sort(productsSorter).map((p) => {
              return (
                <li
                  onClick={() =>
                    route(
                      `/products/${p.id}/edit?back=${encodeURIComponent(
                        `/products?brand=${brand || ''}`,
                      )}`,
                    )
                  }
                  key={p.id}
                  class={cn(
                    'list-group-item p-2 d-flex justify-content-between align-items-center',
                    {
                      'bg-danger bg-opacity-25':
                        Object.values(p.inventory).length === 0,
                    },
                  )}
                >
                  <div
                    style={{
                      maxWidth: 'calc(100% - 2rem)',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div class='text-truncate' style={{ marginRight: '.5rem' }}>
                      {p.name}
                    </div>
                    {(Object.values(p.inventory).length && (
                      <Secondary>
                        ({sumBy(Object.values(p.inventory), 'amount')})
                      </Secondary>
                    )) ||
                      null}
                  </div>
                  <ChevronRight />
                </li>
              );
            })}
          </ul>
        </>
      )}

      {activeProducts.length === 0 && <EmptyList />}
    </>
  );
};

export default ProductsIndex;
