import { useState } from 'preact/hooks';
import { useRouter, route } from 'preact-router';

import { userSignal } from '@/signals';
import {
  getMonth,
  getFullYear,
  getDate,
  isRangeInRanges,
  subtractRange,
  mergeRanges,
} from '@/utils';
import { createOrUpdateWorkDays } from '@/apis';
import Loader from '@/components/Loader';
import Calendar from '@/components/Calendar';
import { Day, WorkDays, Slot } from '@/types';
import { Mode } from '@/enums';
import { workDaysSignal } from '@/signals/workdays';
import { DEFAULT_ERROR } from '@/constants';

type PossibleSearchParams = 'day' | 'month' | 'year';

const HomeRoute = () => {
  const [{ matches }] =
    useRouter<Record<PossibleSearchParams, string | undefined>>();
  const { day, month, year } = matches;

  const [activeMonth, setActiveMonth] = useState<number>(
    month ? +month : getMonth(),
  );
  const [activeYear, setActiveYear] = useState<number>(
    year ? +year : getFullYear(),
  );
  const [activeDay, setActiveDay] = useState<number | null>(
    day ? +day : getDate(),
  );
  const [mode, setMode] = useState<Mode>(Mode.REGULAR);

  const onSlotClick = (slot: Slot) => {
    if (mode === Mode.REGULAR && activeDay !== null) {
      return route(
        `/appointments/new?day=${activeDay}&month=${activeMonth}&year=${activeYear}&slot=${JSON.stringify(
          slot,
        )}&back=${encodeURIComponent(
          `/?day=${activeDay}&month=${activeMonth}&year=${activeYear}`,
        )}`,
      );
    }

    if (mode === Mode.SET_BREAK) {
      let newSlots: Slot[] = [];
      const currentSlots = workDaysSignal.value[activeDay as Day]!;

      if (isRangeInRanges(currentSlots, slot)) {
        newSlots = subtractRange(currentSlots, slot);
      } else {
        newSlots = mergeRanges([...currentSlots, slot]);
      }

      const newDays = { ...workDaysSignal.value, [activeDay as Day]: newSlots };
      workDaysSignal.value = newDays;

      // optimistic update
      workDaysSignal.value = newDays;
      createOrUpdateWorkDays(`${activeYear}:${activeMonth}`, newDays).catch(
        () => {
          alert(DEFAULT_ERROR);
        },
      );
    }
  };

  const onClick = (d: number) => {
    if (mode === Mode.SET_DAYS) {
      const newDays: WorkDays = { ...workDaysSignal.value };

      if (workDaysSignal.value[d as Day]) {
        delete newDays[d as Day];
      } else {
        newDays[d as Day] = [];
      }

      // optimistic update
      workDaysSignal.value = newDays;
      createOrUpdateWorkDays(`${activeYear}:${activeMonth}`, newDays).catch(
        () => {
          alert(DEFAULT_ERROR);
        },
      );
    }

    if (mode === Mode.SET_BREAK || mode === Mode.REGULAR) {
      setActiveDay(d);
    }
  };

  if (!userSignal.value) return <Loader />;

  return (
    <Calendar
      onChange={(_m, _y) => {
        setActiveMonth(_m);
        setActiveYear(_y);
      }}
      isDayDisabled={(_d, _ds) => mode !== Mode.SET_DAYS && !_ds[_d as Day]}
      month={activeMonth}
      year={activeYear}
      mode={mode}
      activeDay={activeDay}
      setActiveDay={setActiveDay}
      onClick={onClick}
      // Timeslots props
      onSlotClick={onSlotClick}
      onAppointmentClick={(a) =>
        route(
          `/appointments/${a.id}/edit?back=${encodeURIComponent(
            `/?day=${activeDay!}&month=${activeMonth}&year=${activeYear}`,
          )}`,
        )
      }
    >
      <div class='d-flex mb-2'>
        <div className='btn-group' style={{ flex: 1 }}>
          <input
            type='radio'
            class='btn-check'
            name='options-outlined'
            id='secondary-outlined-1'
            checked={mode === Mode.REGULAR}
            onClick={() => setMode(Mode.REGULAR)}
          />
          <label
            style={{ flex: 1 }}
            class='btn-sm btn btn-outline-secondary'
            for='secondary-outlined-1'
          >
            Записати
          </label>

          <input
            type='radio'
            class='btn-check'
            name='options-outlined'
            id='secondary-outlined-2'
            checked={mode === Mode.SET_BREAK}
            onClick={() => setMode(Mode.SET_BREAK)}
          />
          <label
            style={{ flex: 1 }}
            class='btn-sm btn btn-outline-secondary'
            for='secondary-outlined-2'
          >
            Перерва
          </label>

          <input
            type='radio'
            class='btn-check'
            name='options-outlined'
            id='secondary-outlined-3'
            checked={mode === Mode.SET_DAYS}
            onClick={() => setMode(Mode.SET_DAYS)}
          />
          <label
            style={{ flex: 1 }}
            class='btn-sm btn btn-outline-secondary'
            for='secondary-outlined-3'
          >
            Робочі дні
          </label>
        </div>
      </div>
    </Calendar>
  );
};

export default HomeRoute;
