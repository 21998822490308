import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';

import { createProcedure } from '@/apis';
import ProceduresForm from '@/components/ProceduresForm';
import ProceduresTabs from '@/components/ProceduresTabs';
import { RequestStatus } from '@/enums';
import { Procedure } from '@/types';
import { DEFAULT_ERROR } from '@/constants';

const ProceduresNew = () => {
  const [{ matches }] = useRouter();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Procedure) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    createProcedure(properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  return (
    <>
      <ProceduresTabs />
      <ProceduresForm onSubmit={onSubmit} requestStatus={requestStatus} />
    </>
  );
};

export default ProceduresNew;
