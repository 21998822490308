import * as db from 'firebase/firestore';
import { b, isInventoryValid } from './_common';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';
import { Inventory, Sale } from 'cosdb-types';
import { getProductById } from '@/signals';

export const createSaleAndUpdateProduct = async ({ sale }: { sale: Sale }) => {
  const product = getProductById(sale.productId);

  if (!product) return Promise.reject(new Error('PRODUCT_NOT_FOUND'));

  const { inventory = {} } = product;
  const procurementId = Object.keys(sale.inventory)[0];
  const newInventory: Inventory = Object.fromEntries(
    Object.entries(inventory)
      .map(([k, { amount, cost, timestamp }]) => {
        // not touching unrelated procurements
        if (k !== procurementId) return [k, { amount, cost, timestamp }];

        // existing procurement, last item
        if (amount === 1) return null;

        // existing procurement, not last item
        return [k, { amount: amount - 1, cost, timestamp }];
      })
      .filter((i) => i !== null),
  );

  if (!isInventoryValid(newInventory)) {
    return Promise.reject(new Error('INVENTORY_INVALID'));
  }

  try {
    await db
      .writeBatch(firestore)
      .set(db.doc(...b(), 'sales', sale.id), sale)
      .update(db.doc(...b(), 'products', product.id), {
        inventory: newInventory,
      })
      .commit();
  } catch (e) {
    logEvent(AnalyticsEvent.CreateSaleAndUpdateProductError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
};
