import { Procurement, ProcurementType } from 'cosdb-types';
import { getProductById } from '@/signals';
import * as db from 'firebase/firestore';
import { b, isInventoryValid } from './_common';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';

export const deleteProcurementAndUpdateProduct = ({
  procurement,
}: {
  procurement: Procurement;
}) => {
  // if not for sale - just removing procurement is enough
  if (procurement.type !== ProcurementType.PRODUCT_FOR_SALE) {
    return db.deleteDoc(db.doc(...b(), 'procurements', procurement.id));
  }

  const product = getProductById(procurement.entityId);

  if (!product) {
    return Promise.reject(new Error('PRODUCT_NOT_FOUND'));
  }

  const { amount } = product.inventory[procurement.id] || {};

  if (amount !== procurement.amount) {
    return Promise.reject(new Error('PROCUREMENT_DIRTY'));
  }

  const inventory = Object.fromEntries(
    Object.entries(product.inventory).filter(([k]) => k !== procurement.id),
  );

  if (!isInventoryValid(inventory)) {
    return Promise.reject(new Error('INVENTORY_INVALID'));
  }

  try {
    // if for sale - remove procurement and also update product inventory
    return db
      .writeBatch(firestore)
      .delete(db.doc(...b(), 'procurements', procurement.id))
      .update(db.doc(...b(), 'products', product.id), { inventory })
      .commit();
  } catch (e: unknown) {
    logEvent(AnalyticsEvent.DeleteProcurementAndUpdateProductError, {
      message: (e as { message: string }).message,
    });

    return Promise.reject(e);
  }
};
