import { useRouter } from 'preact-router';
import { v4 as uuid } from 'uuid';

import ButtonSubmit from '@/components/ButtonSubmit';
import { RequestStatus } from '@/enums';
import Instagram from '@/svgs/instagram.svg';
import { Client } from '@/types';
import { buildNewClient } from '@/signals';
import SimplifiedDatepicker from './SimplifiedDatepicker';
import { deleteField, Timestamp } from 'firebase/firestore';

type Props = {
  onSubmit: (c: Client) => void;
  entity?: Client;
  requestStatus: RequestStatus;
  onChange: {
    phoneNumber: (e: Event) => void;
  };
};

const ClientsForm = ({
  onSubmit,
  entity,
  requestStatus,
  onChange: { phoneNumber },
}: Props) => {
  const [{ url }] = useRouter();
  const model = entity || buildNewClient();

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const timestamp = Timestamp.now();
    const normalized: Client = {
      deleted: false,
      id: entries.id.toString() || uuid(),
      firstName: entries.firstName.toString(),
      lastName: entries.lastName.toString(),
      phoneNumber: entries.phoneNumber.toString(),
      instagramUrl: entries.instagramUrl.toString(),
      notes: entries.notes.toString(),
      birthDate:
        entries.day !== undefined &&
        entries.day !== '' &&
        entries.month !== undefined &&
        entries.month !== '' &&
        entries.year !== undefined &&
        entries.year !== ''
          ? Timestamp.fromDate(
              new Date(
                Date.UTC(
                  +entries.year,
                  +entries.month - 1,
                  +entries.day,
                  12,
                  0,
                  0,
                ),
              ),
            )
          : (deleteField() as Timestamp),

      // timestamps
      createdAt: entity ? entity.createdAt : timestamp,
      updatedAt: timestamp,
    };

    onSubmit(normalized);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div class='mb-2'>
        <label for='firstName' class='form-label'>
          Ім&apos;я
        </label>
        <input
          type='text'
          class='form-control'
          id='firstName'
          name='firstName'
          defaultValue={model.firstName}
          required
        />
      </div>

      <div class='mb-2'>
        <label for='lastName' class='form-label'>
          Прізвище
        </label>
        <input
          type='text'
          class='form-control'
          id='lastName'
          name='lastName'
          defaultValue={model.lastName}
        />
      </div>

      <div class='mb-2'>
        <label class='form-label'>Дата народження</label>
        <SimplifiedDatepicker
          noLabel
          day={model.birthDate?.toDate().getUTCDate()}
          month={
            model.birthDate
              ? model.birthDate?.toDate().getUTCMonth() + 1
              : undefined
          }
          year={model.birthDate?.toDate().getUTCFullYear()}
        />
      </div>

      <label for='instagramUrl' class='form-label'>
        Instagram
      </label>
      <div class='d-flex mb-2'>
        <input
          type='text'
          class='text-truncate form-control'
          id='instagramUrl'
          name='instagramUrl'
          defaultValue={model.instagramUrl}
        />
        <a
          href={model.instagramUrl}
          class='btn btn-link d-flex align-items-center justify-content-center'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Instagram width='20' />
        </a>
      </div>

      <div class='mb-2'>
        <label for='phoneNumber' class='form-label'>
          Номер телефону
        </label>
        <input
          type='text'
          class='form-control'
          id='phoneNumber'
          name='phoneNumber'
          defaultValue={model.phoneNumber}
          onChange={phoneNumber}
          required
        />
      </div>

      <div class='mb-2'>
        <label for='notes' class='form-label'>
          Нотатки
        </label>
        <textarea
          class='form-control'
          id='notes'
          name='notes'
          rows={4}
          defaultValue={model.notes}
        />
      </div>

      <input type='hidden' name='id' value={model.id} />

      <ButtonSubmit
        requestStatus={requestStatus}
        text={url.match('/edit') ? 'Зберегти зміни' : 'Створити клієнта'}
      />
    </form>
  );
};

export default ClientsForm;
