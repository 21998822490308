import { route, useRouter } from 'preact-router';
import { useState } from 'preact/hooks';

import { createAppointment } from '@/apis';
import AppointmentsForm from '@/components/AppointmentsForm';
import AppointmentsTabs from '@/components/AppointmentsTabs';
import { RequestStatus } from '@/enums';
import { Appointment } from '@/types';
import { DEFAULT_ERROR } from '@/constants';

const AppointmentsNew = () => {
  const [{ matches }] = useRouter();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.DEFAULT);

  const onSubmit = (properties: Appointment) => {
    setRequestStatus(RequestStatus.IN_PROGRESS);

    createAppointment(properties)
      .then(() => {
        setRequestStatus(RequestStatus.IS_DONE);
        setTimeout(() => route(matches?.back || '/'), 1000);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.DEFAULT);
        alert(DEFAULT_ERROR);
      });
  };

  return (
    <>
      <AppointmentsTabs />
      <AppointmentsForm onSubmit={onSubmit} requestStatus={requestStatus} />
    </>
  );
};

export default AppointmentsNew;
