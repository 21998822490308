import { useState, useEffect } from 'preact/hooks';
import { getAppointmentsByYearAndMonth } from '@/apis';
import { proceduresSignal } from '@/signals';
import EmptyList from '@/components/EmptyList';
import Loader from '@/components/Loader';
import { Appointment } from '@/types';
import { PaymentStatus } from '@/enums';

const AnalyticsAppointments = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  const [appointments, setAppointments] = useState<Appointment[] | null>(null);

  useEffect(() => {
    setAppointments(null);

    getAppointmentsByYearAndMonth({ year, month })
      .then((_appointments) =>
        setAppointments(
          _appointments.filter(
            (a) => a.paymentStatus !== PaymentStatus.NOT_PAID,
          ),
        ),
      )
      .catch(() =>
        alert(
          'Не вдалося витягнути список записів згідно крітеріїв. Спробуйте пізніше.',
        ),
      );
  }, [month, year]);

  const prices1: Record<string, number> = {};
  const prices2: Record<string, number> = {};
  let amounts: Record<string, number> = {};

  appointments?.forEach((a) => {
    Object.entries(a.price1).forEach(([procedureId, price]) => {
      prices1[procedureId] = (prices1[procedureId] || 0) + price;
      amounts[procedureId] = (amounts[procedureId] || 0) + 1;
    });

    Object.entries(a.price2).forEach(([procedureId, price]) => {
      prices2[procedureId] = (prices2[procedureId] || 0) + price;
    });
  });

  // sorting by amount, desc
  amounts = Object.fromEntries(
    Object.entries(amounts).sort((a, b) => b[1] - a[1]),
  );

  const sum = (acc: number, i: number) => acc + i;

  const tips = appointments
    ?.filter((a) => a.tips !== 0)
    .map((a) => a.tips)
    .reduce(sum, 0);

  const cashSum =
    (appointments
      ?.filter((a) => a.paymentStatus === PaymentStatus.PAID_BY_CASH)
      .reduce((acc: number, i: Appointment) => {
        return (
          acc +
          Object.entries(i.price2).reduce(
            (acc, [k, v]) => acc + v - i.price1[k],
            0,
          )
        );
      }, 0) || 0) + (tips || 0);
  const cardSum =
    appointments
      ?.filter((a) => a.paymentStatus === PaymentStatus.PAID_BY_CARD)
      .reduce((acc: number, i: Appointment) => {
        return (
          acc +
          Object.entries(i.price2).reduce(
            (acc, [k, v]) => acc + v - i.price1[k],
            0,
          )
        );
      }, 0) || 0;

  return (
    <>
      <h5 class='mt-4'>Процедури:</h5>
      {appointments === null && <Loader />}
      {(appointments !== null && appointments.length) === 0 && <EmptyList />}
      {appointments !== null && appointments.length > 0 && (
        <>
          <table
            style={{ fontSize: '.85rem' }}
            class='table table-striped table-sm table-bordered'
          >
            <thead>
              <tr>
                <th>Назва</th>
                <th style={{ width: '15%' }}>Кільк.</th>
                <th style={{ width: '15%' }}>Варт.</th>
                <th style={{ width: '15%' }}>Соб.</th>
                <th style={{ width: '15%' }}>Дох.</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(amounts).map((procedureId, index) => {
                const procedure = proceduresSignal.value.find(
                  (proc) => proc.id === procedureId,
                );

                return (
                  <tr>
                    <td data-locator={`procedures-row-${index}-name`}>
                      {procedure?.name}
                    </td>
                    <td data-locator={`procedures-row-${index}-count`}>
                      {amounts[procedureId]}
                    </td>
                    <td data-locator={`procedures-row-${index}-price2`}>
                      {prices2[procedureId].toFixed()}
                    </td>
                    <td data-locator={`procedures-row-${index}-price1`}>
                      {prices1[procedureId].toFixed()}
                    </td>
                    <td data-locator={`procedures-row-${index}-diff`}>
                      {(prices2[procedureId] - prices1[procedureId]).toFixed()}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={4}>Чайові</td>
                <td>{tips}</td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td data-locator='procedures-total-count'>
                  {Object.values(amounts).reduce(sum, 0)}
                </td>
                <td data-locator='procedures-total-price2'>
                  {Object.values(prices2).reduce(sum, 0).toFixed()}
                </td>
                <td data-locator='procedures-total-price1'>
                  {Object.values(prices1).reduce(sum, 0).toFixed()}
                </td>
                <td data-locator='procedures-total-diff'>
                  {(
                    Object.values(prices2).reduce(sum, 0) -
                    Object.values(prices1).reduce(sum, 0) +
                    (tips || 0)
                  ).toFixed()}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ fontSize: 14, color: '#777777', textAlign: 'right' }}>
            <i data-locator='products-cost'>{`Кешем: ${Intl.NumberFormat(
              'uk-UA',
              {
                style: 'currency',
                currency: 'UAH',
              },
            ).format(cashSum)}`}</i>
            <br />
            <i data-locator='products-price'>{`Карткою: ${Intl.NumberFormat(
              'uk-UA',
              {
                style: 'currency',
                currency: 'UAH',
              },
            ).format(cardSum)}`}</i>
          </div>
        </>
      )}
    </>
  );
};

export default AnalyticsAppointments;
