import { useRouter } from 'preact-router';
import { proceduresSignal } from '@/signals';

export const useProcedure = () => {
  const [{ matches }] = useRouter<{ procedureId: string }>();
  const entity =
    proceduresSignal.value.find((p) => p.id === matches?.procedureId) || null;

  return { entity };
};
