import { Timestamp } from "firebase/firestore";

export enum ProcurementType {
  EXPENSE = "EXPENSE",
  PRODUCT_FOR_WORK = "PRODUCT_FOR_WORK",
  PRODUCT_FOR_SALE = "PRODUCT_FOR_SALE",
}

export type Company = {
  settings: Settings;
  fcmTokens: string[];
  featureFlags?: string[];
  id: string;
};

export type Settings = {
  analyticsEnabled?: boolean;
  productsEnabled?: boolean;
  expensesEnabled?: boolean;
  birthdayPush?: boolean;
  startTime?: number;
};

export type Brand = {
  id: string;
  value: string;
  thumbnail?: string;
  description?: string;
};

type ProcurementId = string;
type ItemsLeft = number;

export type InventoryItem = {
  amount: ItemsLeft;
  cost: number;
  timestamp: number; // millis
};
export type Inventory = Record<ProcurementId, InventoryItem>;

export type Product = {
  id: string;
  price: number;
  salePrice?: number;
  brandId: string;
  name: string;
  description: string;
  deleted: boolean;
  inventory: Inventory;

  // shop stuff
  thumbnail?: string;
  categoryIds?: string[];
  showInShop?: boolean;
  isBestseller?: boolean;
};

export type _Procurement = {
  id: string;
  price: number;
  year: number;
  month: number;
  day: number;
  amount: number;
  type: ProcurementType;
  entityId: string;
  notes?: string;
};

export type ProductForSaleProcurement = _Procurement & {
  type: ProcurementType.PRODUCT_FOR_SALE;
};

export type ProductForWorkProcurement = _Procurement & {
  type: ProcurementType.PRODUCT_FOR_WORK;
};

export type ExpenseProcurement = _Procurement & {
  type: ProcurementType.EXPENSE;
};

export type ProductProcurement =
  | ProductForSaleProcurement
  | ProductForWorkProcurement;

export type Procurement =
  | ProductForSaleProcurement
  | ProductForWorkProcurement
  | ExpenseProcurement;

export type Sale = {
  id: string;
  clientId: string;
  productId: string;
  year: number;
  month: number;
  day: number;
  inventory: Inventory;
  price: number;
};

export enum OrderStatus {
  OPEN = "OPEN",
  REJECTED = "REJECTED",
  FULLFILLED = "FULLFILLED",
}

export type OrderDetails = {
  id: string;
  contactInfo: string;
  name: string;
  shippingDetails: string;
  orderId: string;
};

export type Order = {
  id: string;
  status: OrderStatus;
  createdAt: Timestamp;
  products: {
    id: string;
    amount: number;
    price: number;
  }[];
};
