import { route, useRouter } from 'preact-router';

import ArrowLeft from '@/svgs/arrow-left.svg';
import Trash from '@/svgs/trash.svg';
import { DEFAULT_ERROR } from '@/constants';
import { Procurement, ProcurementType } from 'cosdb-types';
import { deleteProcurementAndUpdateProduct } from '@/apis/deleteProcurementAndUpdateProduct';

type Props = {
  entity?: Procurement;
};

const ProcurementsTabs = ({ entity }: Props) => {
  const [{ matches }] = useRouter<{ back: string; type: ProcurementType }>();
  const { type } = matches;

  const onDelete = () => {
    if (!entity) return;

    const p = confirm('Чи справді ви хочете видалити закупівлю?');

    if (!p) return;

    return deleteProcurementAndUpdateProduct({ procurement: entity })
      .then(() => route(matches?.back || '/'))
      .catch((err: Error) => {
        if (err.message === 'PRODUCT_NOT_FOUND') {
          alert(`Продукт ${entity.entityId} не знайдено`);
        } else if (err.message === 'PROCUREMENT_DIRTY') {
          alert(
            'Не можна поміняти цю закупілю, схоже вже були продажі банок з неї.',
          );
        } else {
          alert(DEFAULT_ERROR);
        }
      });
  };

  return (
    <ul class='nav nav-tabs tabs mb-2'>
      <li class='nav-item'>
        <a href={matches?.back || '/'} class='nav-link text-primary'>
          <ArrowLeft />
        </a>
      </li>
      {!entity && (
        <li class='nav-item'>
          <button type='button' class='nav-link active'>
            {type === ProcurementType.PRODUCT_FOR_SALE && 'Додати закупівлю'}
            {type === ProcurementType.PRODUCT_FOR_WORK && 'Додати закупівлю'}
            {type === ProcurementType.EXPENSE && 'Додати витрату'}
          </button>
        </li>
      )}
      {entity && (
        <>
          <li class='nav-item'>
            <span class='nav-link active'>Інфо</span>
          </li>
          <li class='nav-item ms-auto'>
            <button
              data-locator='delete-procurement'
              type='button'
              aria-label='Видалити'
              onClick={onDelete}
              class='nav-link text-danger'
            >
              <Trash />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default ProcurementsTabs;
