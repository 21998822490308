type Mode = 'numeric' | 'decimal';

const PATTERNS: Record<Mode, string> = {
  numeric: '\\d+',
  decimal: '\\d+((.|,)(\\d+))?',
};

export const NumberInput = ({
  defaultValue,
  label,
  _key,
  inputMode,
  required = false,
}: {
  required?: boolean;
  inputMode: Mode;
  _key: string;
  label: string;
  defaultValue: number;
}) => {
  return (
    <>
      <label for={_key} class='form-label'>
        {label}
      </label>
      <input
        inputMode={inputMode}
        class='form-control'
        required={required}
        type='text'
        name={_key}
        id={_key}
        pattern={PATTERNS[inputMode]}
        defaultValue={defaultValue !== 0 ? defaultValue.toString() : undefined}
      />
    </>
  );
};
