import { route, useRouter } from 'preact-router';
import cn from 'classnames';

import { useProduct } from '@/hooks';
import { updateProduct } from '@/apis';
import ArrowLeft from '@/svgs/arrow-left.svg';
import Trash from '@/svgs/trash.svg';
import { brandsSignal } from '@/signals';
import { DEFAULT_ERROR } from '@/constants';

const ProductsTabs = () => {
  const [{ matches, url }] = useRouter();
  const { entity } = useProduct();

  const onDelete = () => {
    if (!entity) return null;

    const p = confirm(
      `Чи справді ви хочете видалити продукт ${
        brandsSignal.value.find((b) => entity.brandId === b.id)?.value
      } - ${entity.name}?`,
    );

    if (!p) return;

    updateProduct(entity.id, { deleted: true })
      .then(() => route(matches?.back || '/'))
      .catch(() => alert(DEFAULT_ERROR));
  };

  return (
    <ul class='nav nav-tabs mb-2'>
      <li class='nav-item'>
        <a href={matches?.back || '/'} class='nav-link text-primary'>
          <ArrowLeft />
        </a>
      </li>
      {!entity && (
        <li class='nav-item'>
          <button type='button' class='nav-link active'>
            Новий продукт
          </button>
        </li>
      )}
      {entity && (
        <>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/products/${entity.id}/edit`),
              })}
              href={`/products/${entity.id}/edit?back=${encodeURIComponent(
                matches?.back || '/',
              )}`}
            >
              Інфо
            </a>
          </li>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/products/${entity.id}/procurements`),
              })}
              href={`/products/${
                entity.id
              }/procurements?back=${encodeURIComponent(matches?.back || '/')}`}
            >
              Закуп.
            </a>
          </li>
          <li class='nav-item'>
            <a
              class={cn('nav-link', {
                active: url.includes(`/products/${entity.id}/sales`),
              })}
              href={`/products/${entity.id}/sales?back=${encodeURIComponent(
                matches?.back || '/',
              )}`}
            >
              Продажі
            </a>
          </li>
          <li class='nav-item ms-auto'>
            <button
              type='button'
              data-locator='delete-product'
              aria-label='Видалити'
              onClick={onDelete}
              class='nav-link text-danger'
            >
              <Trash />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default ProductsTabs;
